import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import csc from "country-state-city"
import Select from "react-select";
import './event.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';


import AWS from 'aws-sdk';
import axios from 'axios';

//storing image in bucket
AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
});


interface FormData {
    title: string; description: string; location: string; startDate: string; endDate: string; country: string; city: string; state: string
}

const initialFormData: FormData = {
    title: '', description: '', location: '', startDate: '', endDate: '', country: '', city: '', state: ''
};


const AddEvent = () => {

    const [checked, setChecked] = useState(true);
    const handleCheckboxChange = () => {
        setChecked(!checked);

        if (!checked) {
            setFormData({ ...formData, endDate: '' })
        }
    };



    const today = new Date().toISOString().split('T')[0];

    const navigate = useNavigate()
    const s3 = new AWS.S3();


    const [addButton, setAddButton] = useState(false);
    const [minEndDate, setMinEndDate] = useState(today);

    const [image, setImage] = useState(null);
    const [imageErr, setImageErr] = useState(false);
    const [img, setImg] = useState('');



    async function handleImageFile(e) {
        setImageErr(false);
        const selectedFile = e.target.files[0];



        if (selectedFile) {
            if (selectedFile.size > 5 * 1024 * 1024) { // Check if the file size is greater than 2MB
                setImageErr(true);
                toast.error('Image size must be 5MB or less', {
                    position: "bottom-right",
                });
                return;
            }

            setImage(selectedFile.name);

            const params = {
                Bucket: 'chaturvedi-v2',
                Key: `${Date.now()}.${selectedFile.name}`,
                Body: selectedFile,
                ACL: 'public-read-write'
            };

            try {
                const { Location } = await s3.upload(params).promise();
                setImg(Location);
            } catch (error) {
                toast.error(error, {
                    position: "bottom-right",
                });
            }
        }
    }





    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<FormData>>({});

    const [endData, setEndData] = useState('')



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    };

    function handleTextareaInput(event) {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }






    const validateForm = () => {
        const errors: Partial<FormData> = {};

        if (!formData.title.trim()) {
            errors.title = 'Title is required';
        }
        if (!formData.description.trim()) {
            errors.description = 'Description is required';
        }

        if (!formData.startDate.trim()) {
            errors.startDate = 'Please enter your Star Date ';
        }

        if (!formData.endDate.trim() && checked == false) {
            errors.endDate = 'Please enter your End Date';
        }
        if (!formData.location.trim()) {
            errors.location = 'Location is required';
        }

        if (!formData.country.trim()) {
            errors.country = 'Country is required';
        }

        if (!formData.state.trim()) {
            errors.state = 'State is required';
        }
        if (!formData.city.trim()) {
            errors.city = 'City is required';
        }


        return errors;
    };


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const errors = validateForm();

            if (Object.keys(errors).length === 0) {
                const data = {
                    ...formData,
                    endDate: formData.startDate === today ? today : formData.endDate,
                    image: img,
                    country: updatedCountries.find((country) => country.value === formData.country)?.label || '',
                    state: updatedStates(formData.country).find((state) => state.value === formData.state)?.label || '',
                    city: updatedCities(formData.state).find((city) => city.value === formData.city)?.label || '',

                }


                try {
                    setAddButton(true)
                    axios.post(`${process.env.REACT_APP_API_BASE_URL}/event/add`, data)
                        .then((res) => {
                            toast.success('Event Created Successfully!', { position: "bottom-right" });
                            navigate('/events/list')
                        }).catch((err) => {
                            setAddButton(false)
                            toast.error(err?.response?.data?.message, { position: "bottom-right" });
                        })

                } catch (err) {
                    setAddButton(false)
                    toast.error(err?.response?.data?.error, { position: "bottom-right" });
                }
            } else {
                setErrors(errors);
                return;
            }
        } catch (err) {
            console.log("Event Error 1 ====", err)
        }
    };


    const countries = csc.getAllCountries();

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
    }));

    const updatedStates = (countryId) => {
        return csc
            .getStatesOfCountry(countryId)
            .map((state) => ({ label: state.name, value: state.id }));
    }

    const updatedCities = (stateId) => {
        return csc
            .getCitiesOfState(stateId)
            .map((city) => ({ label: city.name, value: city.id }));
    }

    
    const getMinimumDate = (date) => {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);

        return minDate.toISOString().split('T')[0];
    };



    return (
        <>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                    gap: '10', background: '#fff', padding: 15,
                    margin: 30, borderRadius: 10
                }}
            >
                <div style={{ margin: 4, width: '100%' }}>
                    <p
                        style={{
                            margin: 4, width: '100%', color: '#000',
                            fontWeight: 600, marginBottom: '30px'
                        }}>
                        Add Event
                    </p>
                </div>
                <Grid item xs={12}>

                    <div className='TopBox-Add-iser-2'>
                        <div style={{ margin: 9, width: '20%' }}>
                            <TextField className='add-user-inside'
                                label="Title" name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                            />
                            {errors.title && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.title}
                                </span>
                            )}
                        </div>




                        <div>
                            <div style={{ margin: 9, width: '100%' }}>
                                <TextField className='add-user-inside'
                                    type='date'
                                    name="startDate"
                                    inputProps={{
                                        min: today,
                                    }}
                                    value={formData.startDate}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        setMinEndDate(e.target.value);
                                    }}
                                />
                                {errors.startDate && (
                                    <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                        {errors.startDate}
                                    </span>
                                )}
                            </div>
                            <label className='label-area-10'>
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    // disabled={checked === true}
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                />
                                Start Date is same as end date
                            </label>
                        </div>
       



                        {
                            !checked ? (
                                <div style={{ margin: 9, width: '20%' }}>
                                    <TextField className='add-user-inside'
                                        type='date'
                                        name="endDate"
                                        inputProps={{
                                            min: formData.startDate == today ? getMinimumDate(minEndDate) : minEndDate,
                                            // min:
                                            // max: checked ? '' : formData.startDate,
                                        }}
                                        value={formData.endDate}
                                        onChange={(e) => {
                                            handleInputChange(e)
                                            setEndData(e.target.value);

                                        }}
                                    />
                                    {errors.endDate && (
                                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                            {errors.endDate}
                                        </span>
                                    )}
                                </div>
                            ) : null
                        }


                        <div style={{ margin: 9 }}>

                            <textarea
                                className='auto-resize-textarea-10'
                                placeholder='location'
                                id="location"
                                name="location"
                                value={formData.location}
                                onChange={(e) => {
                                    handleTextareaInput(e);
                                    handleInputChange(e);

                                }}
                                rows={4}
                                cols={50}
                            />
                            {errors.location && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.location}
                                </span>
                            )}
                        </div>



                        <div style={{ margin: 9, width: '20%' }}>
                            <Select
                                id="country"
                                name="country"
                                placeholder="Select Country"
                                options={updatedCountries}
                                value={updatedCountries.find((country) => country.value === formData.country)}
                                onChange={(selectedOption) => {
                                    setFormData({
                                        ...formData,
                                        country: selectedOption?.value || '',
                                        state: '',
                                        city: '',
                                    });
                                    setErrors((prevErrors) => ({ ...prevErrors, country: undefined }));

                                }

                                }

                            />
                            {errors.country && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.country}
                                </span>
                            )}
                        </div>


                        <div style={{ margin: 9, width: '20%' }}>
                            <Select
                                id="state"
                                name="state"
                                placeholder="Select State"
                                options={updatedStates(formData.country)}
                                value={formData.state ? updatedStates(formData.country).find((state) => state.value === formData.state) : null}
                                onChange={(selectedOption) => {
                                    setFormData({ ...formData, state: selectedOption?.value || '', city: '' });
                                    setErrors((prevErrors) => ({ ...prevErrors, state: undefined }));

                                }}
                            />
                            {errors.state && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.state}
                                </span>
                            )}
                        </div>

                        <div style={{ margin: 9, width: '20%' }}>
                            <Select
                                id="city"
                                name="city"
                                placeholder="Select City"
                                options={updatedCities(formData.state)}
                                value={formData.city ? updatedCities(formData.state).find((city) => city.value === formData.city) : null}
                                onChange={(selectedOption) => {
                                    setFormData({ ...formData, city: selectedOption?.value || '' });
                                    setErrors((prevErrors) => ({ ...prevErrors, city: undefined }));

                                }}
                            />
                            {errors.city && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.city}
                                </span>
                            )}
                        </div>




                    </div>


                    <div className='description-image-container'>
                        <div className='description-box-area' style={{ margin: 9, width: '100%' }}>
                            <ReactQuill
                                className='new-user-class'
                                value={formData.description}
                                onChange={(value) => {
                                    setFormData({ ...formData, description: value })
                                    setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));


                                }}
                            />
                            {errors.description && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.description}
                                </span>
                            )}
                        </div>


                    </div>

                    <div className='' style={{ margin: 12, width: '20%', position: 'relative' }}>
                        <input className='image-input-class-22' type="file" onChange={handleImageFile} />
                        <div className='attach-icon'>
                            <AttachFileIcon />
                            <label>choose an Image</label>
                        </div>
                        {img && <img className='image-inside-2' src={img} style={{ maxWidth: "170px" }} />}

                        {/* <input type="file" onChange={handleImageFile} /> */}
                        {/* <span className='error-msg'>Image size must be 5MB or less</span> */}
                    </div>
                </Grid>

                <div style={{ margin: 9, width: '100%' }}>
                    <Button variant="contained" type="submit" disabled={addButton == true}>
                        Submit
                    </Button>
                </div>
            </form>


        </>
    );
}
export default AddEvent;



