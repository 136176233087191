import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { toast } from 'react-toastify';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// AWS S3 Configuration
AWS.config.update({
  accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
  secretAccessKey: '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',
  region: 'ap-south-1'
});

const AddMatrimonial = () => {
  const today = new Date().toISOString().split('T')[0];

  const [image, setImage] = useState(null);
  const [img, setImg] = useState('');
  const [imageErr, setImageErr] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const s3 = new AWS.S3();


  async function handleImageFile(e) {
    setImageErr(false);
    setImage(e.target.files[0]?.name);

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'chaturvedi-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };
      try {
        const { Location } = await s3.upload(params).promise();
        setImg(Location);
      } catch (error) {
        toast.error(error, {
          position: 'bottom-right'
        });
      }
    }
  }

  useEffect(() => {
    handleUsersData();
  }, []);

  const handleUsersData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/members/getAll`);
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      DOB: '',
      qualification: '',
      aboutMe: '',
      user: [],
      image:'',
      status: true,
    },
    
    validationSchema: Yup.object({
      name: Yup.string().required('Please select a name'),
      DOB: Yup.date().required('Please select a date of birth'),
      qualification: Yup.string().required('Please select a qualification'),
      aboutMe: Yup.string().required('Please provide details about yourself'),
      user: Yup.array().required('Please select a User'),
    }),
    onSubmit: async (values) => {
      console.log(values,"lllllllllll");
      
      // await handleImageUploadToS3(imageFile);
      const data = {
        name: values.name,
        DOB: values.DOB,
        qualification: values.qualification,
        aboutMe: values.aboutMe,
        user: values.user.join(','),
        status: values.status.toString(),
        image: img,
      };
      console.log(data,"gggggggggg")

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/matrimonial/addMatrimonial`, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        toast.success('Matrimonial created successfully!', {
          position: 'bottom-right',
        });
        navigate('/matrimonial/list');
      } catch (error) {
        toast.error(error?.response?.data?.message || 'An error occurred', {
          position: 'bottom-right',
        });
      }
    },
  });

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Card>
            <CardHeader title="Add Matrimonial" />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                       label="Date of Birth"
                      type="date"
                      name="DOB"
                      inputProps={{
                        max: today,
                      }}
                      value={formik.values.DOB}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.DOB && Boolean(formik.errors.DOB)}
                      helperText={formik.touched.DOB && formik.errors.DOB}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Qualification"
                      name="qualification"
                      value={formik.values.qualification}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.qualification && Boolean(formik.errors.qualification)}
                      helperText={formik.touched.qualification && formik.errors.qualification}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth label="About Me"
                      name="aboutMe"
                      value={formik.values.aboutMe}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.aboutMe && Boolean(formik.errors.aboutMe)}
                      helperText={formik.touched.aboutMe && formik.errors.aboutMe}
                    />
                  </Grid>
                  <Grid item xs={2}>
  <Select
    multiple
    fullWidth
    label="User"
    name="user"
    value={formik.values.user}
    onChange={(event) =>
      formik.setFieldValue('user', event.target.value)
    }
    renderValue={(selected) =>
      selected
        .map((id) => users.find((user) => user._id === id)?.name)
        .join(', ')
    }
  >
    {users.map((user) => (
      <MenuItem key={user._id} value={user._id}>
        <Checkbox checked={formik.values.user.includes(user._id)} />
        <ListItemText primary={user.name} />
      </MenuItem>
    ))}
  </Select>
  {formik.touched.user && formik.errors.user && (
    <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
      {formik.errors.user}
    </div>
  )}
</Grid>

                  
                 

                  <Grid>
                  <div style={{ margin: 9 }}>
                <input type="file" onChange={handleImageFile} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AttachFileIcon />
                  <label>choose an Image</label>
                </div>
                {img && <img src={img} style={{ maxWidth: '170px' }} />}
              </div>
                  </Grid>

                </Grid>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '10px',
                  }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AddMatrimonial;