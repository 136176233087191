import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Divider,
  Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
import './AddBlog.css'; // Create this CSS file if needed

// AWS S3 Configuration
AWS.config.update({
  accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
  secretAccessKey: '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',
  region: 'ap-south-1'
});

const initialValues = { Title: '', Content: '' };

const validationSchema = Yup.object({
  Title: Yup.string().required('Title is required'),
  Content: Yup.string().required('Content is required')
});

const AddBlog = () => {
  const [image, setImage] = useState(null);
  const [img, setImg] = useState('');
  const [imageErr, setImageErr] = useState(false);
  const [addButton, setAddButton] = useState(false);

  const s3 = new AWS.S3();

  async function handleImageFile(e) {
    setImageErr(false);
    setImage(e.target.files[0]?.name);

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'chaturvedi-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };
      try {
        const { Location } = await s3.upload(params).promise();
        setImg(Location);
      } catch (error) {
        toast.error(error, {
          position: 'bottom-right'
        });
      }
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const requestBody = {
          ...values,
          image: img
        };

        try {
          setAddButton(true);
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/blogs/addBlog`,
            requestBody
          );
          toast.success('Blog added successfully!', {
            position: 'bottom-right'
          });
          // Optionally navigate to another page
        } catch (err) {
          setAddButton(false);
          toast.error(err.response.data.message, { position: 'bottom-right' });
        }
      }
    });

  return (
    <form onSubmit={handleSubmit} style={{ margin: 30 }}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Add Blog" />
          <Divider />
          <CardContent>
            <Box component="form" noValidate autoComplete="off">
              <div style={{ margin: 9 }}>
                <TextField
                  label="Title"
                  name="Title"
                  type="text"
                  value={values.Title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  required
                />
                {errors.Title && touched.Title ? (
                  <p style={{ color: 'red' }}>{errors.Title}</p>
                ) : null}
              </div>

              <div style={{ margin: 9 }}>
                <TextField
                  label="Content"
                  name="Content"
                  multiline
                  rows={4}
                  value={values.Content}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  required
                />
                {errors.Content && touched.Content ? (
                  <p style={{ color: 'red' }}>{errors.Content}</p>
                ) : null}
              </div>

              <div style={{ margin: 9 }}>
                <input type="file" onChange={handleImageFile} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AttachFileIcon />
                  <label>choose an Image</label>
                </div>
                {img && <img src={img} style={{ maxWidth: '170px' }} />}
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <div style={{ margin: 9, width: '100%' }}>
        <Button variant="contained" type="submit" disabled={addButton}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default AddBlog;
