import { Typography, Button, Grid } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useNavigate } from 'react-router';



function PageHeader() {
  const navigate = useNavigate();

  const handleUser = () => {
    navigate('/notifications/add-notification');
  }
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Announcement
        </Typography>

      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          onClick={handleUser}
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          Create Announcements
        </Button>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
