import { useLocation, useParams } from 'react-router';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import './ViewEvent.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';


const ViewEvent = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [locationData, setLocation] = useState('');
    const [image, setImage] = useState('');
    const [members, setMembers] = useState([])






    const stripHtmlTags = (htmlString) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || '';
    };


    const viewData = async () => {

        try {
            const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/event/${id}`)
            
            setDescription(data?.data?.data?.description);

            setMembers(data?.data?.data?.members)
            setTitle(data?.data?.data?.title)

            setStartDate(data?.data?.data?.startDate)
            setEndDate(data?.data?.data?.endDate)
            setImage(data?.data?.data?.image)
            setLocation(data?.data?.data?.location)
        } catch (error) {
            console.log("error is = ", error)
        }

    }
    useEffect(() => {
        viewData()
    }, [])


    return (


        <div className='detail-page-section'>
            <p className='info-text'>Event</p>

            <div className='Section-new'>

                <div className='Main-box-11'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Title Price</p>
                        <p className='order-text-22'>{title}</p>
                    </div>


                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>  StartDate</p>
                        <p className='order-text-22'>{moment(startDate).format('D MMMM YYYY')}</p>
                    </div>


                </div>


                <div className='Main-box-11'>
                    <div className='order-info-box'>
                        <p className='order-text-1'>  Location </p>
                        <p className='order-text-22'>{locationData} </p>
                    </div>
                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'>End Date  </p>
                        <p className='order-text-22'>{moment(endDate).format('D MMMM YYYY')}</p>
                    </div>
                </div>



                <div className='Main-box-11'>
                    <div className='order-info-box'>
                        {
                            image ? <p className='order-text-1'>Image</p> : null
                        }

                        {image ? (
                            <img className='food-image22-new' src={image} style={{ width: '50px', height: '50px', marginRight: '5px' }}
                            />
                        ) : (
                            null
                        )}
                    </div>

                    <div className='order-info-box order-info-box-right'>
                        <p className='order-text-1'> Description</p>
                        <div  className='order-text-new-description' dangerouslySetInnerHTML={{ __html: description }} />
                        {/* <p className='order-text-22'>`{description}`</p> */}
                    </div>
                </div>


    

                <div className='Main-box-1'>
                    <div className='order-info-box'>
                        <p className='order-text-1'> Join Members </p>
                        {
                            members.map((data) => (

                                <ul>
                                    <li>{data?.name} </li>
                                </ul>

                            ))
                        }
                    </div>





                </div>

            </div>


            <div>
                <Button className='back-btn-2' onClick={() => navigate('/events')}>
                    Back to Events
                </Button>
            </div>
        </div>
    );


}
export default ViewEvent;









