import {
    Box,
    TextField,
    Card,
    CardContent,
    Typography,
    Button,
    CardHeader,
    Divider,
    Grid,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
  } from '@mui/material';
  
  import { useNavigate, useParams } from 'react-router-dom';
  import { useFormik } from 'formik';
  import * as Yup from 'yup';
  import { useEffect, useState } from 'react';
  import AWS from 'aws-sdk';
  import axios from 'axios';
  import { toast } from 'react-toastify';
  import AttachFileIcon from '@mui/icons-material/AttachFile';
  
  // AWS S3 Configuration
  AWS.config.update({
    accessKeyId: 'YOUR_AWS_ACCESS_KEY_ID',
    secretAccessKey: 'YOUR_AWS_SECRET_ACCESS_KEY',
    region: 'ap-south-1',
  });
  
  const EditMatrimonial = () => {
    const { id } = useParams();
    const [img, setImg] = useState('');
    const [users, setUsers] = useState([]);
    const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
    const navigate = useNavigate();
  
    const s3 = new AWS.S3();
  
    async function handleImageFile(e) {
      const file = e.target.files[0];
      if (file) {
        const params = {
          Bucket: 'chaturvedi-v2',
          Key: `${Date.now()}.${file.name.split('.').pop()}`,
          Body: file,
          ACL: 'public-read', // Changed to public-read
        };
  
        try {
          const { Location } = await s3.upload(params).promise();
          setImg(Location);
        } catch (error) {
          toast.error(error.message || 'Error uploading image', {
            position: 'bottom-right',
          });
        }
      }
    }
  
    useEffect(() => {
      fetchMatrimonialData();
      fetchUsersData();
    }, [id]);
  
    const fetchMatrimonialData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/matrimonial/getMatrimonial/${id}`);
        const { name, DOB, qualification, aboutMe, user, image } = response.data;
        console.log(response.data,"response datatat");
        
        const formateDOB = DOB ? new Date(DOB).toISOString().split('T')[0]:' ';
  
        formik.setValues({
          name,
          DOB: formateDOB,
          qualification,
          aboutMe,
          user: Array.isArray(user) ? user.map(u => u) : [],
          image,
        });
        setImg(image);
      } catch (error) {
        console.error('Error fetching matrimonial data:', error);
      } finally {
        setInitialValuesLoaded(true);
      }
    };
  
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/members/getAll`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    const formik = useFormik({
      initialValues: {
        name: '',
        DOB: '',
        qualification: '',
        aboutMe: '',
        user: [],
        image: '',
      },
      validationSchema: Yup.object({
        name: Yup.string().required('Please enter a name'),
        DOB: Yup.date().required('Please select a date of birth'),
        qualification: Yup.string().required('Please enter a qualification'),
        aboutMe: Yup.string().required('Please provide details about yourself'),
        user: Yup.array().required('Please select a User'),
      }),
      onSubmit: async (values) => {
        const data = {
          ...values,
          user: values.user.join(','),
          image: img,
        };
        console.log(data,"dddddddppppppp");
        
  
        try {
          await axios.put(`${process.env.REACT_APP_API_BASE_URL}/matrimonial/updateMatrimonial/${id}`, data, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          toast.success('Matrimonial updated successfully!', {
            position: 'bottom-right',
          });
          navigate('/matrimonial/list');
        } catch (error) {
          toast.error(error?.response?.data?.message || 'An error occurred', {
            position: 'bottom-right',
          });
        }
      },
      enableReinitialize: true,
    });
  
    if (!initialValuesLoaded) {
      return <div>Loading...</div>;
    }
  
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Card>
              <CardHeader title="Edit Matrimonial" />
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        type="date"
                        label="Date of Birth"
                        name="DOB"
                        value={formik.values.DOB}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.DOB && Boolean(formik.errors.DOB)}
                        helperText={formik.touched.DOB && formik.errors.DOB}
                        InputLabelProps={{ shrink: true }} // Keeps the label above the input
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Qualification"
                        name="qualification"
                        value={formik.values.qualification}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.qualification && Boolean(formik.errors.qualification)}
                        helperText={formik.touched.qualification && formik.errors.qualification}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="About Me"
                        name="aboutMe"
                        value={formik.values.aboutMe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.aboutMe && Boolean(formik.errors.aboutMe)}
                        helperText={formik.touched.aboutMe && formik.errors.aboutMe}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Select
                        multiple
                        fullWidth
                        label="User"
                        name="user"
                        value={formik.values.user}
                        onChange={(event) => formik.setFieldValue('user', event.target.value)}
                        renderValue={(selected) =>
                          selected.map((id) => users.find((user) => user._id === id)?.name).join(', ')
                        }
                      >
                        {users.map((user) => (
                          <MenuItem key={user._id} value={user._id}>
                            <Checkbox checked={formik.values.user.includes(user._id)} />
                            <ListItemText primary={user.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.user && formik.errors.user && (
                        <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.user}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ margin: 9 }}>
                        <input type="file" onChange={handleImageFile} />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <AttachFileIcon />
                          <label>Choose an Image</label>
                        </div>
                        {img && <img src={img} alt="Uploaded" style={{ maxWidth: '170px' }} />}
                      </div>
                    </Grid>
                  </Grid>
                  <Divider />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '10px',
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  };
  
  export default EditMatrimonial;
  ``
  