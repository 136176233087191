import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Tooltip, Modal, Divider, Box, FormControl, InputLabel, Card, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme, CardHeader, Button
} from '@mui/material';
import { DataGrid, GridToolbar, GridValueGetterParams, GridRowParams } from '@mui/x-data-grid';
import axios from 'axios';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';



interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}


const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MemberList: FC<CategoryListTableProps> = ({ }) => {
  const [clickedID, setClickedID] = useState(null)
  const [open, setOpen] = useState(false);

  const handleOpen = (id) => {
    setOpen(true);
    setClickedID(id)
  }

  const handleClose = () => {
    setOpen(false);
    setClickedID(null)
  }

  const [Loading, setLoading] = useState(false)
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState([]);
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();
  const [deleteButton, setDeleteButton] = useState(false);

  const adminRows = cryptoOrders.filter((row) => row.role === 'Admin');

  const columns = [
    { field: 'name', headerName: 'Name', width: 170 },
    { field: 'user', headerName: 'User', width: 170},

    { field: 'memberSince', headerName: 'MemberSince', width: 170,
      renderCell: (params) => {
        // Get the date value
        const rawDate = new Date(params?.row?.memberSince);
    
        // Extract day, month, and year, and format to dd-mm-yyyy
        const day = String(rawDate.getDate()).padStart(2, '0');
        const month = String(rawDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = rawDate.getFullYear();
    
        // Format as dd-mm-yyyy
        const formattedDate = `${day}-${month}-${year}`;
    
        return (
          <Typography variant="body1" color="text.primary" noWrap>
            {formattedDate}
          </Typography>
        );
      }
    },
    { field: 'status', headerName: 'Status', width: 170 },
    { field: 'post', headerName: 'Post', width: 170 },


 
   
    {
      field: 'Actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit User" arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  },
                  color: theme.palette.primary.main
                }}
                color="inherit"
                size="small"
              >

                <EditTwoToneIcon fontSize="small"
                  onClick={() => navigate(`/members/edit/${params?.row._id}
                  `)}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete User" arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  },
                  color: theme.palette.primary.main
                }}
                color="inherit"
                size="small"
              >

                {params.row.role == "Admin" ? "" : (
                  <DeleteTwoToneIcon fontSize='small'
                    onClick={() => handleOpen(params.row._id)}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                  />
                )}


              </IconButton>
            </Tooltip>


          </>

        );
      },
    },
  ];

  const handleDeleteUsers = async () => {
    try {
      setDeleteButton(true);
      for (const id of selectedCryptoOrders) {
        console.log("data is => ", id);
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/memberpost/deleteMemberPost/${id}`);
      }
      setDeleteButton(false);
      toast.success('Selected Condolance Deleted Successfully!', { position: "bottom-right" });
      setSelectedCryptoOrders([]);
      fetchCondolanceData();
    } catch (error) {
      setDeleteButton(false);
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  };

  const fetchCondolanceData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/memberpost/getAllMemberPosts`);
      console.log(response,"dddddddddddddd");
      if (response?.data) {
        setLoading(false)
        const d = response?.data;
        const sortedNonPinnedPosts = d.sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });
        setcryptoOrdersPage(sortedNonPinnedPosts)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    fetchCondolanceData();
  }, []);

  const handleDeleteData = async (id: any) => {
    try {
      setDeleteButton(true)
      let data = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/memberpost/deleteMemberPost/${id}`);
      setDeleteButton(false)
      toast.success('User Deleted Successfully!', { position: "bottom-right" });
      fetchCondolanceData();
      handleClose()
    } catch (error) {
      setDeleteButton(false)
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }


  useEffect(() => {
    
  }, [selectedCryptoOrders]);

  const isCheckboxDisabled = selectedCryptoOrders.some((row) => row.role === 'Admin');


  

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Are you sure to delete?
          </Typography>
          <hr />


          <Button type="submit" sx={{ mt: 2, mr: 2 }} variant="contained" onClick={() => handleDeleteData(clickedID)}
            disabled={deleteButton}
          >
            Yes
          </Button>

          <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={() => handleClose()}>
            Cancel
          </Button>

        </Box>
      </Modal>

      <Box className="innerr_box" sx={{ p: 3, width: "auto" }}>
        <Divider variant="middle" />
        <div className="table">
          <DataGrid
            rows={cryptoOrders}
            getRowId={(row) => row._id}
            columns={columns}
            className="mybonustable widthtable"
            slots={{ toolbar: GridToolbar }}
            checkboxSelection
         

            onRowClick={(params) => {
              if (params?.row?.name || params?.row?.email || params?.row?.mobile ||
                params?.row?.gotra || params?.row?.fatherName || params?.row?.city || params?.row?.state ||
                params?.row?.familyId) {
                if (params.row.role === "Admin") {
                  toast.warning("Admin Can't Be Deleted", { position: "bottom-right" });
                } else {
                  
                }
              }
            }}
            isRowSelectable={(params: GridRowParams) => params.row.role !== "Admin"}


            onRowSelectionModelChange={(newSelection) => {

              setSelectedCryptoOrders(newSelection);
            }}

            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: ['']
                }
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
          />

        </div>
        <Button
          variant="contained"
          color="warning"
          onClick={handleDeleteUsers}
          disabled={selectedCryptoOrders.length === 0 || deleteButton}
          style={{ display: selectedCryptoOrders.length === 0 || deleteButton ? 'none' : 'block' }}
        >
          Delete Users
        </Button>
      </Box>

    </>

  );
};

export default MemberList;



