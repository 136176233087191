import { Card } from '@mui/material';
import UsersListTable from './UsersListTable';
export interface CryptoUser {
  id: string;
  status: boolean;
  name: string;
  email: string;
  mobile: string;
}
function NotificationList() {

  return (
    <Card>
      <UsersListTable/>
    </Card>
  );
}

export default NotificationList;
