import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { toast } from 'react-toastify';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// AWS S3 Configuration
AWS.config.update({
  accessKeyId: 'YOUR_AWS_ACCESS_KEY_ID',
  secretAccessKey: 'YOUR_AWS_SECRET_ACCESS_KEY',
  region: 'ap-south-1',
});

const EditCondolanceMessage = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [image, setImage] = useState(null);
  const [img, setImg] = useState<string | null>(null);
  const [users, setUsers] = useState([]);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
  const navigate = useNavigate();
  
  const s3 = new AWS.S3();

  // Handle Image File Upload
  async function handleImageFile(e) {
    const file = e.target.files[0];
    if (file) {
      const params = {
        Bucket: 'chaturvedi-v2',
        Key: `${Date.now()}.${file.name.split('.').pop()}`,
        Body: file,
        ACL: 'public-read',
      };

      try {
        const { Location } = await s3.upload(params).promise();
        setImg(Location);
      } catch (error) {
        toast.error(error.message || 'Image upload failed', {
          position: 'bottom-right',
        });
      }
    }
  }

  useEffect(() => {
    fetchCondolanceData(); // Fetch data to pre-fill the form
    handleNotificationData(); // Fetch users
  }, [id]);

  // Fetch existing condolance data
  // Fetch existing condolance data
const fetchCondolanceData = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/condolance/getCondolance/${id}`);
    const { name, message, image, user } = response.data;

    // Ensure that `user` is always an array
    const userArray = Array.isArray(user) ? user.map(u => u._id) : [user];

    // Set Formik values after loading data
    formik.setValues({
      name,
      message,
      user: userArray, // Ensure this is an array of user IDs
      image,
    });

    setImg(image); // Set image if already uploaded
  } catch (error) {
    console.error('Error fetching condolance data:', error);
  } finally {
    setInitialValuesLoaded(true); // Ensure loading state is set to true after fetching
  }
};


  // Fetch users list
  const handleNotificationData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/members/getAll`);
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {

      
      name: '',
      message: '',
      image: '',
      user: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please select a name'),
      message: Yup.string().required('Please select a message'),
      user: Yup.array().required('Please select a User'),
    }),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        message: values.message,
        user: values.user.join(','), // Send user IDs as a string
        image: img,
      };
  
      try {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/condolance/updateCondolance/${id}`, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        toast.success('Condolance message updated successfully!', {
          position: 'bottom-right',
        });
        navigate('/condolance-messages/list');
      } catch (error) {
        toast.error(error?.response?.data?.message || 'An error occurred', {
          position: 'bottom-right',
        });
      }
    },
    enableReinitialize: true, // Allow Formik to reinitialize with the fetched values
  });

  if (!initialValuesLoaded) {
    return <div>Loading...</div>; // Add a loader while data is being fetched
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Card>
            <CardHeader title="Edit Condolance Message" />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Select
                      multiple
                      fullWidth
                      label="User"
                      name="user"
                      value={formik.values.user}
                      onChange={(event) => formik.setFieldValue('user', event.target.value)}
                      renderValue={(selected) =>
                        selected
                          .map((id) => users.find((user) => user._id === id)?.name)
                          .join(', ')
                      }
                    >
                      {users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          <Checkbox checked={formik.values.user.includes(user._id)} />
                          <ListItemText primary={user.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.user && formik.errors.user && (
                      <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.user}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.message && Boolean(formik.errors.message)}
                      helperText={formik.touched.message && formik.errors.message}
                    />
                  </Grid>

                  {/* Image Upload Field */}
                  <Grid item xs={12}>
                    <div style={{ margin: 9 }}>
                      <input type="file" onChange={handleImageFile} />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AttachFileIcon />
                        <label>Choose an Image</label>
                      </div>
                      {img && <img src={img} alt="Uploaded" style={{ maxWidth: '170px' }} />}
                    </div>
                  </Grid>
                </Grid>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '10px',
                  }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EditCondolanceMessage;
