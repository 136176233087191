import { useEffect, useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';

import Select from 'react-select';
import csc from "country-state-city"
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';


const EditGotra = () => {

    const navigate = useNavigate();
    const { id } = useParams();


    const [gotra, setGotra] = useState("")
    const [gotraErr, setGotraErr] = useState(false)
    const [submitData, setSubmitData] = useState(false)
    console.log(submitData);


    const getData = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/gotra/${id}`)
            setGotra(data?.data?.data?.gotra)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getData()
    }, []);


    const handleEdit = async (e) => {
        try {
            e.preventDefault();
            if (gotra == "") {
                setGotraErr(true)
                return;
            }
            await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/gotra/edit/${id}`, { gotra: gotra }).then((res) => {
                // setSubmitData(true)
                // toast.success('Gotra Update Successfully!', { position: "bottom-right" });
                navigate('/gotra');
            }).catch((err) => {
                // setSubmitData(false)

                toast.error(err, {
                    position: "bottom-right",
                });
            })

        } catch (error) {
            console.log(error);
        }
    }



    return (
        <>
            <Helmet>
                <title>Edit Gotra</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Gotra" />
                            <Divider />
                            <CardContent>

                                <form action="" onSubmit={handleEdit}>

                                    <div className='box-1-top-22'>
                                        <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Gotra"
                                                name="gotra"
                                                type='text'
                                                value={gotra}
                                                onChange={(e) => {
                                                    setGotra(e.target.value)
                                                    setGotraErr(false);
                                                }}
                                            />
                                            {gotraErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Gotra is required.
                                                </span>
                                            )}
                                        </div>
                                    </div>




                                    <div>
                                        <Button className="button-box" type='submit'>Submit</Button>

                                        {/* <Button className="button-box" type='submit' disabled={submitData == true}>Submit</Button> */}
                                        <Button className="button-box" onClick={() => navigate('/gotra')}>Back to Gotra</Button>
                                    </div>
                                </form>



                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditGotra;
