import {
    Box,
    TextField,
    Card,
    CardContent,
    Typography,
    Button,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
  } from '@mui/material';
  import { useNavigate } from 'react-router-dom';
  import { useFormik } from 'formik';
  import * as Yup from 'yup';
  import { useEffect, useState } from 'react';
  import axios from 'axios';
  import { toast } from 'react-toastify';
  import './AddNotification.css';
  import AttachFileIcon from '@mui/icons-material/AttachFile';
  import { Type } from 'react-toastify/dist/utils';
  
  // Initial form values
  const initialValues = {
    Receiver: '',
    Title: '',
    Content: '',
    membersIds: [],
    type: '',
    SubNotification: ''
  };
  
  // Validation schema
  // const validationSchema = Yup.object({
  //     Receiver: Yup.string().when('type', {
  //         is: 'Announcement',
  //         then: Yup.string().required('Please select a Receiver'),
  //         otherwise: Yup.string()
  //     }),
  //     Title: Yup.string().required('Title is required'),
  //     type: Yup.string().required('Please select a type'),
  // });
  // Validation schema
  const validationSchema = Yup.object({
    Receiver: Yup.string().required('Please select a Receiver'),
    Title: Yup.string().required('Title is required'),
    type: Yup.string().required('Please select a type')
  });
  
  // AddNotification Component
  const AddNotification = () => {
    const [img, setImg] = useState('');
    const [notificationData, setNotificationData] = useState([]);
    const navigate = useNavigate();
  
    // Fetch members for the dropdown
    const handleNotificationData = async () => {
      try {
        let data = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/members/getAll`
        );
        setNotificationData(data?.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      handleNotificationData();
    }, []);
  
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue
    } = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        const selectedMembersIds = values.membersIds;
        const requestBody = {
          ...values,
          image: img,
          membersIds: selectedMembersIds,
          Type: values.type,
          SubNotification: values.SubNotification
        };
  
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/notifications/addnotification`,
            requestBody
          );
          toast.success('Notification created successfully!', {
            position: 'bottom-right'
          });
          navigate('/notifications/list');
        } catch (error) {
          toast.error(error?.response?.data?.message || 'An error occurred', {
            position: 'bottom-right'
          });
        }
      }
    });
  
    return (
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '10px',
          background: '#fff',
          padding: 15,
          margin: 30,
          borderRadius: 10
        }}
      >
        <Grid className="carrd-box" item xs={12}>
          <Card>
            <CardHeader title="Add Notification" />
            <Divider />
            <CardContent>
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '35ch' } }}
                noValidate
                autoComplete="off"
              >
                {/* Type Dropdown */}
                <div
                  className="Main-div-1"
                  style={{ margin: 9, position: 'relative' }}
                >
                  <InputLabel className="heading-receiver" htmlFor="type">
                    Type
                  </InputLabel>
                  <Select
                    className="text-area-style22"
                    sx={{ width: '35ch', ml: 1 }}
                    label="Type"
                    value={values.type}
                    name="type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="type"
                  >
                    <MenuItem value="Announcement">Announcement</MenuItem>
                    <MenuItem value="Notification">Notification</MenuItem>
                  </Select>
                  {errors.type && touched.type ? (
                    <p style={{ color: 'red' }}>{errors.type}</p>
                  ) : null}
                </div>
  
                {/* Conditionally render fields based on type selection */}
                {values.type === 'Notification' && (
                  <div
                    className="Main-div-1"
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      gap: '10px'
                    }}
                  >
                    <div style={{ margin: 9, position: 'relative' }}>
                      <InputLabel
                        className="heading-receiver"
                        htmlFor="notification-type"
                      >
                        Select Type of Notification
                      </InputLabel>
                      <Select
                        className="text-area-style22"
                        sx={{ width: '35ch', ml: 1 }}
                        label="Test Options"
                        name="SubNotification" // Ensure this is the correct name
                        value={values.SubNotification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="subNotification" // Ensure this is the correct id
                      >
                        <MenuItem value="test1">Test 1</MenuItem>
                        <MenuItem value="test2">Test 2</MenuItem>
                        <MenuItem value="test3">Test 3</MenuItem>
                        <MenuItem value="test4">Test 4</MenuItem>
                      </Select>
                    </div>
                  </div>
                )}
  
                {(values.type === 'Announcement' ||
                  values.type === 'Notification') && (
                  <div
                    className="Main-div-1"
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      gap: '10px'
                    }}
                  >
                    <div style={{ margin: 9, position: 'relative' }}>
                      <InputLabel className="heading-receiver" htmlFor="receiver">
                        Receiver
                      </InputLabel>
                      <Select
                        className="text-area-style22"
                        sx={{ width: '35ch', ml: 1 }}
                        label="Receiver"
                        value={values?.Receiver}
                        name="Receiver"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="receiver"
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="Member">Member</MenuItem>
                        <MenuItem value="Sub Admin">Sub Admin</MenuItem>
                      </Select>
                      {errors.Receiver && touched.Receiver ? (
                        <p style={{ color: 'red' }}>{errors.Receiver}</p>
                      ) : null}
                    </div>
  
                    {values.Receiver === 'Member' && (
                      <div style={{ margin: 9, position: 'relative' }}>
                        <InputLabel
                          className="heading-receiver"
                          htmlFor="members"
                        >
                          Members
                        </InputLabel>
                        <Select
                          className="text-area-style22"
                          sx={{ width: '35ch', ml: 1 }}
                          multiple
                          value={values?.membersIds}
                          name="membersIds"
                          onChange={(event) => {
                            const { value } = event.target;
                            setFieldValue(
                              'membersIds',
                              typeof value === 'string' ? value.split(',') : value
                            );
                          }}
                          onBlur={handleBlur}
                          id="membersIds"
                          renderValue={(selected) => (
                            <div>
                              {selected.map((id) => {
                                const selectedMember = notificationData.find(
                                  (member) => member._id === id
                                );
                                return (
                                  <Typography key={id} variant="body2">
                                    {selectedMember?.name}
                                  </Typography>
                                );
                              })}
                            </div>
                          )}
                        >
                          {notificationData.map((member) => (
                            <MenuItem key={member._id} value={member._id}>
                              <Checkbox
                                checked={
                                  values?.membersIds.indexOf(member._id) > -1
                                }
                              />
                              <ListItemText primary={member.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
  
                    <div style={{ margin: 9 }}>
                      <TextField
                        className="text-area-style22"
                        label="Title"
                        name="Title"
                        type="text"
                        value={values?.Title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.Title && touched.Title ? (
                        <p style={{ color: 'red' }}>{errors.Title}</p>
                      ) : null}
                    </div>
                    {/* Content and Image Upload */}
                    <div className="Main-div-1">
                      <div style={{ margin: 9, marginLeft: 18 }}>
                        <textarea
                          className="text-area-1 text-area-style22 auto-resize-textarea-2"
                          placeholder="Content"
                          id="Content"
                          name="Content"
                          value={values?.Content}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rows={4}
                        />
                      </div>
                    </div>
  
                    <div className="Main-div-1">
                      <div
                        style={{
                          margin: 12,
                          position: 'relative',
                          marginLeft: 18
                        }}
                      >
                        <input
                          className="image-input-class-22"
                          type="file"
                          onChange={(e) =>
                            setImg(URL.createObjectURL(e.target.files[0]))
                          }
                        />
                        <div className="attach-icon">
                          <AttachFileIcon />
                          <label>Choose an Image</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
  
                {/* Content and Image Upload */}
                {/* <div className='Main-div-1'>
                                  <div style={{ margin: 9, marginLeft: 18 }}>
                                      <textarea
                                          className='text-area-1 text-area-style22 auto-resize-textarea-2'
                                          placeholder='Content'
                                          id="Content"
                                          name="Content"
                                          value={values?.Content}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          rows={4}
                                      />
                                  </div>
                              </div> */}
  
                {/* <div className='Main-div-1'>
                                  <div style={{ margin: 12, position: 'relative', marginLeft: 18 }}>
                                      <input className='image-input-class-22' type="file" onChange={(e) => setImg(URL.createObjectURL(e.target.files[0]))} />
                                      <div className='attach-icon'>
                                          <AttachFileIcon />
                                          <label>Choose an Image</label>
                                      </div>
                                  </div>
                              </div> */}
              </Box>
            </CardContent>
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '10px'
              }}
            >
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </div>
          </Card>
        </Grid>
      </form>
    );
  };
  
  export default AddNotification;
  