import { Card } from '@mui/material';
import MatrimonialListTable from './MatrimonialListTable';
export interface CryptoUser {
  id: string;
  status: boolean;
  name: string;
  email: string;
  mobile: string;
}
function MatrimaonialList() {

  return (
    <Card>
      <MatrimonialListTable/>
    </Card>
  );
}

export default MatrimaonialList;
