import { useEffect, useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import './EditNotification.css';
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
    // signatureVersion: 'v4'
});

const EditBlog = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const [Title, setTitle] = useState('');
    const [Content, setContent] = useState('');
    const [image, setImage] = useState('');

    const [TitleErr, setTitleErr] = useState(false);
    const [ContentErr, setContentErr] = useState(false);

    const [submitData, setSubmitData] = useState(false)

    const handleData = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/blogs/getblog/${id}`)

            setTitle(data?.data?.Title)
            setContent(data?.data?.Content)
            setImage(data?.data?.image)
        } catch (error) {
            console.log("error is =>>>>>>>>>>>>>>>>>>>> ", error)
        }
    }

    useEffect(() => {
        handleData()
    }, [])

    const [imageErr, setImageErr] = useState(false);
    const [img, setImg] = useState('');
    
    const s3 = new AWS.S3();
    async function handleImageFile(e) {
        setImageErr(false);
        setImage(e.target.files[0]?.name);

        if (e.target.files[0]?.name) {
            const params = {
                Bucket: 'chaturvedi-v2',
                Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
                Body: e?.target?.files[0],
                ACL: 'public-read-write'
            };

            try {
                const { Location } = await s3.upload(params).promise();
                setImg(Location);
                setImage(Location)
            } catch (error) {
                toast.error(error, {
                    position: "bottom-right",
                });
            }
        }
    }

    const handleEdit = async () => {
        try {
            if (Title == '') {
                setTitleErr(true);
                return;
            }
            if (Content == '') {
                setContentErr(true);
                return;
            }

            setSubmitData(true)
            let data = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/blogs/update/${id}`, {
                // Receiver: receiver, Title: Title, Content: Content, image: img ? img : image
            });
            toast.success('Announcement Update Successfully!', { position: "bottom-right" });
            navigate('/notifications');
        } catch (error) {
            setSubmitData(false);
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }

    return (
        <>
            <Helmet>
                <title>Edit Blog</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Notification" />
                            <Divider />
                            <CardContent>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '50ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >

                               
                                    {/* End of commented section */}

                                    <div className='Main-Box-1 box-2-padding'>
                                    <div className='Box-inside'>
                                            <textarea className='text-area-style'
                                                id="outlined-required"
                                                name="Title"
                                                value={Title ? Title : ''}
                                                onChange={
                                                    (e) => {
                                                        setTitle(e.target.value)
                                                        setTitleErr(false)
                                                    }
                                                }
                                            />
                                            {TitleErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Title is required.
                                                </span>
                                            )}
                                        </div>
                                        <div className='Box-inside'>
                                            <textarea className='text-area-style'
                                                id="outlined-required"
                                                name="Content"
                                                value={Content ? Content : ''}
                                                onChange={
                                                    (e) => {
                                                        setContent(e.target.value)
                                                        setContentErr(false)
                                                    }
                                                }
                                            />
                                            {ContentErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Content is required.
                                                </span>
                                            )}
                                        </div>

                                        <div className='another-inside' style={{ margin: 12 }}>
                                            <input type="file" onChange={handleImageFile} />
                                            {image && <img className='image-div' src={image} />}
                                        </div>
                                    </div>

                                    <div className='btn-box'>
                                        <Button className='done-btn' onClick={handleEdit} disabled={submitData === true}>Submit</Button>
                                        <Button className='done-btn' onClick={() => navigate('/blogs')}>Back to Blogs</Button>
                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditBlog;
