import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css'; // Import styles
import { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios from 'axios';






const AddGotra = () => {
    const navigate = useNavigate()

    const [gotra, setGotra] = useState("")
    const [gotraErr, setGotraErr] = useState(false)


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            if (gotra == '') {
                setGotraErr(true)
                return
            }

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/gotra/add`, { gotra: gotra }).then((res) => {
    
                toast.success('Gotra Added Successfully!', { position: "bottom-right" });
                navigate('/gotra');
            }).catch((err) => {
                toast.error(err?.response?.data?.message, {
                    position: "bottom-right",
                });
            })
        } catch (err) {
            console.log("Event Error 1 ====", err);
        }
    };






    return (
        <>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                    gap: '10', background: '#fff', padding: 15,
                    margin: 30, borderRadius: 10
                }}
            >
                <div style={{ margin: 4, width: '100%' }}>
                    <p
                        style={{
                            margin: 4, width: '100%', color: '#000',
                            fontWeight: 600, marginBottom: '30px'
                        }}>
                        Add Gotra
                    </p>
                </div>


                <div style={{ margin: 9 }}>



                    <TextField className='date-box'
                        id="outlined-required"
                        type='gotra'
                        name="gotra"
                        value={gotra}
                        placeholder='Enter Gotra'
                        onChange={(e) => {
                            setGotra(e.target.value)
                            setGotraErr(false)

                        }}
                    />

                    {gotraErr && (
                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                            This Field is required
                        </span>
                    )}
                </div>



                <div style={{ margin: 9, width: '100%' }}>
                    {/* <Button variant="contained" type="submit" disabled={addButton == true}> */}
                    <Button variant="contained" type="submit" >

                        Submit
                    </Button>
                </div>
            </form>


        </>
    );
}
export default AddGotra;



