// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonBox{
    display: flex;
    gap: 14px;
    margin-bottom: 10px;

}

.dlt-btn{
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/content/applications/Event/ListEvent/ListEvent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;;AAEvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".ButtonBox{\n    display: flex;\n    gap: 14px;\n    margin-bottom: 10px;\n\n}\n\n.dlt-btn{\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
