import { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Card,
    CardContent,
    Typography,
    Button,
    CardHeader,
    Container,
    Divider,
    Grid,
    FormControl,
    InputLabel,

} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { CatchingPokemon } from '@mui/icons-material';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

const EditWaiter = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const initialName = new URLSearchParams(location.search).get('name') || '';
    const initialEmail = new URLSearchParams(location.search).get('email') || '';



    const [name, setName] = useState(initialName);
    const [email, setEmail] = useState(initialEmail);


    const [nameErr, setNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);






    const handleEdit = async () => {
        try {
            if (name == '') {
                setNameErr(true);
                return;
            }
            if (email == '') {
                setEmailErr(true);
                return;
            }


            const response = await axios.patch(
                `${process.env.REACT_APP_API_BASE_URL}/waiter/edit/${id}`,
                {
                    name: name,
                    email: email,
                }
            );
            toast.success('Waiter Updated Successfully!', { position: "bottom-right" });
            setTimeout(() => {

                navigate('/waiters');
            }, 1000)
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    };



    return (
        <>
            <Helmet>
                <title>Edit Waiters</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Dish" />
                            <Divider />
                            <CardContent>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField
                                            id="outlined-required"
                                            label="Name"
                                            name="name"
                                            value={name.trim()}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                setNameErr(false);
                                            }}
                                        />
                                        {nameErr && (
                                            <span
                                                style={{
                                                    color: 'red',
                                                    display: 'flex',
                                                    paddingTop: '10px'
                                                }}
                                            >
                                                Name is required.
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <TextField
                                            id="outlined-required"
                                            label="Email"
                                            name="email"
                                            value={email.trim()}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailErr(false);
                                            }}
                                        />
                                        {emailErr && (
                                            <span
                                                style={{
                                                    color: 'red',
                                                    display: 'flex',
                                                    paddingTop: '10px'
                                                }}
                                            >
                                                Email is required.
                                            </span>
                                        )}
                                    </div>


                                    <div>
                                        <Button size="small" onClick={handleEdit}>
                                            Submit
                                        </Button>
                                        <Button onClick={() => navigate('/waiters')}>
                                            Back to Waiters
                                        </Button>
                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditWaiter;
