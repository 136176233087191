import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export function PublicRoute({ children }) {
  const Isloggedin = JSON.parse(localStorage.getItem('IsloggedIn'));
  const location = useLocation();

  return Isloggedin ? (
    <Navigate to="/dashboards/main" state={{ from: location }} />
  ) : (
    children
  );
}
