import {
    Box,
    TextField,
    Card,
    CardContent,
    Typography,
    Button,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
  } from '@mui/material';
  
  import { useNavigate } from 'react-router-dom';
  import { useFormik } from 'formik';
  import * as Yup from 'yup';
  import { useEffect, useState } from 'react';
  import AWS from 'aws-sdk';


  import axios from 'axios';
  import { toast } from 'react-toastify';
  import AttachFileIcon from '@mui/icons-material/AttachFile';
  
  // AWS S3 Configuration
  AWS.config.update({
    accessKeyId: 'YOUR_ACCESS_KEY',
    secretAccessKey: 'YOUR_SECRET_KEY',
    region: 'YOUR_REGION'
  });
  
  const AddMembers = () => {
    const [image, setImage] = useState(null);
    const [imageErr, setImageErr] = useState(false);
    const [imageFile, setImageFile] = useState(null);
  
    const s3 = new AWS.S3();
  
    const [img, setImg] = useState<string | null>(null);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
  
    async function handleImageFile(e) {
        setImageErr(false);
        setImage(e.target.files[0]?.name);
    
        if (e.target.files[0]?.name) {
          const params = {
            Bucket: 'chaturvedi-v2',
            Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
            Body: e?.target?.files[0],
            ACL: 'public-read-write'
          };
          try {
            const { Location } = await s3.upload(params).promise();
            setImg(Location);
          } catch (error) {
            toast.error(error, {
              position: 'bottom-right'
            });
          }
        }
      }
  
    useEffect(() => {
      handleUsersData();
    }, []);
  
    const handleUsersData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/members/getAll`);
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    const formik = useFormik({
      initialValues: {
        name: '',
        post: '',
        memberSince: '',
        user: [],
        status: true,
        image: '',
      },
      validationSchema: Yup.object({
        name: Yup.string().required('Please enter a name'),
        post: Yup.string().required('Please enter a post'),
        user: Yup.array().required('Please select a user'),
      }),
      onSubmit: async (values) => {
        console.log(values,"jjjkkkkjjjj");

        const data = {
          name: values.name,
          post: values.post,
          memberSince: values.memberSince || new Date(),
          user: values.user.join(','),
          status: values.status,
          image: img, // Send the image URL in the payload
        };
        console.log(data,"jjjjjjjjjj");
        
  
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/memberpost/addMemberPost`, JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          toast.success('Member added successfully!');
          navigate('/members/list');
        } catch (error) {
          toast.error('An error occurred');
        }
      },
    });
  
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Card>
              <CardHeader title="Add Member" />
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
  
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Post"
                        name="post"
                        value={formik.values.post}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.post && Boolean(formik.errors.post)}
                        helperText={formik.touched.post && formik.errors.post}
                      />
                    </Grid>
  
                    <Grid item xs={6}>
                      <InputLabel>User</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        name="user"
                        value={formik.values .user}
                        onChange={(event)=>
                          formik.setFieldValue('user', event.target.value)}

                         renderValue={(selected) =>
                         selected
                           .map((id) => users.find((user) => user._id === id)?.name)
                           .join(', ')
                       }
                      >
                        {users.map((user) => (
                          <MenuItem key={user._id} value={user._id}>
                            <Checkbox checked={formik.values.user.includes(user._id)} />
                            <ListItemText primary={user.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.user && formik.errors.user && (
                        <div style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.user}
                        </div>
                      )}
                    </Grid>
  
                   {/* Image Upload Field */}
               
                   <Grid>
                  <div style={{ margin: 9 }}>
                <input type="file" onChange={handleImageFile} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AttachFileIcon />
                  <label>choose an Image</label>
                </div>
                {img && <img src={img} style={{ maxWidth: '170px' }} />}
              </div>
                  </Grid>
  
                  <Grid item xs={6}>
  <InputLabel>Status</InputLabel>
  <Select
    name="status"
    value={formik.values.status.toString()} // Ensure the value is string
    onChange={(e) => formik.setFieldValue("status", e.target.value === "true")} // Convert string back to boolean
    fullWidth
  >
    <MenuItem value="true">Active</MenuItem>
    <MenuItem value="false">Inactive</MenuItem>
  </Select>
</Grid>


                  </Grid>
  
                  <Divider />
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  };
  
  export default AddMembers;