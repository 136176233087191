import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

import EditRider from './content/applications/Riders/EditRider/EditRider';
import EditUser from './content/applications/Users/EditUser/EditUser';
import AddWaiter from './content/applications/Users/profile/AddWaiter/AddWaiter';
import Login from './components/Login';
import { PublicRoute } from './Utils/PublicRoute';
import ProtectedRoute from './Utils/ProtectedRoute';
import EditWaiter from './content/applications/Users/profile/EditWaiter';
import WaiterList from './content/applications/Users/profile/WaiterList/WaiterList';
import ViewRider from './content/applications/Riders/ViewRiders';
import AddNotification from './content/applications/Notification/add-notification';
import EditNotification from './content/applications/Notification/Edit-notification/EditNotification';
import NotificationsList from './content/applications/Notification/notification-listing';
import EditAdmin from './content/applications/Users/EditUser/EditAdmin';
import AddEvent from './content/applications/Event/AddEvent';
import EventListTable from './content/applications/Event/ListEvent/EventListing';
import EditEvent from './content/applications/Event/EditEvent/EditEvent';
import ViewEvent from './content/applications/Event/ViewEvent/ViewEvent';
import ContactList from './content/applications/Contact/contactList';
import { ViewList } from '@mui/icons-material';
import ViewContact from './content/applications/Contact/view';
import AddGotra from './content/applications/Gotra/Add';
import GotraListTable from './content/applications/Gotra/List/GotraListingTable';
import AddHome from './content/applications/Home/AddHome';
import HomeListTable from './content/applications/Home/ListHome/HomeLIsting';
import EditGotra from './content/applications/Gotra/Edit';
import EditHome from './content/applications/Home/EditHome/EditHome';
import UserNotFound from './content/applications/UserNotFound/UserNotFound';
import AddBlog from './content/applications/Blog/add-blog';
import BlogList from './content/applications/Blog/blog-listing';
import EditBlog from './content/applications/Blog/Edit-Blog/EditBlog';
import AddCondolanceMessage from './content/applications/Condolance/condolance';
import AddMatrimonial from './content/applications/Matrimonial/matrimonial';
import CondolenceList from './content/applications/Condolance/List/condolancelist';
import MatrimaonialList from './content/applications/Matrimonial/List/MatrimonialList';
import AddMembers from './content/applications/Members/Members';
import MemberList from './content/applications/Members/List/MemberListTable';
import EditCondolanceMessage from './content/applications/Condolance/edit-page/edit-condolance';
import EditMatrimonialMessage from './content/applications/Matrimonial/Edit/editmatrimonial';
import EditMatrimonial from './content/applications/Matrimonial/Edit/editmatrimonial';
import EditMember from './content/applications/Members/Edit/editMember';
// import Addcondolancemessage from './content/applications/Addcondolancemessage';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications
const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const Waiters = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
// const AddWaiter = Loader(
//   lazy(() => import('src/content/applications/Users/profile'))
// );


const UsersList = Loader(
  lazy(() => import('src/content/applications/Users/user-listing/index'))
);

const BlogsList = Loader(
  lazy(() => import('src/content/applications/Blog/blog-listing/index'))
)
const AddUser = Loader(
  lazy(() => import('src/content/applications/Users/add-user/index'))
);


const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <PublicRoute>
            {' '}
            <Login />
          </PublicRoute>
        )
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            {' '}
            <Login />
          </PublicRoute>
        )
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },

        ]
      },

    ]
  },

  {
    path: 'notifications',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <NotificationsList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <NotificationsList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-notification',
        element: (
          <ProtectedRoute>
            {' '}
            <AddNotification />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-notification/:id',
        element: (
          <ProtectedRoute>
            <EditNotification />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'users',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <UsersList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <UsersList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-user',
        element: (
          <ProtectedRoute>
            {' '}
            <AddUser />
          </ProtectedRoute>
        )
      },
      {
        path: 'add-notification',
        element: (
          <ProtectedRoute>
            {' '}
            <AddNotification />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-user/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditUser />{' '}
          </ProtectedRoute>
        )
      }
    ]
  },



  {
    path: 'blogs',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <BlogsList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <BlogsList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-blog',
        element: (
          <ProtectedRoute>
            {' '}
            <AddBlog />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-blog/:id',
        element: (
          <ProtectedRoute>
            <EditBlog />
          </ProtectedRoute>
        )
      }
    ]
  },


  {
    path: 'admin',
    element: <SidebarLayout />,

    children: [


      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <EditUser />
          </ProtectedRoute>
        )
      },

    ]
  },

  {
    path: 'events',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <EventListTable />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <EventListTable />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            {' '}
            <AddEvent />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-event/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditEvent />
          </ProtectedRoute>
        )
      },
      {
        path: 'view-event/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <ViewEvent />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'contacts',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <ContactList />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <ContactList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'view-list/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <ViewContact />
          </ProtectedRoute>
        )
      }

    ]
  },
  {
    path: 'userNotFound',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <UserNotFound />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <UserNotFound />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'view-list/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <ViewContact />
          </ProtectedRoute>
        )
      }

    ]
  },
  {
    path: 'gotra',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <GotraListTable />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <GotraListTable />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            {' '}
            <AddGotra />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-gotra/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditGotra />
          </ProtectedRoute>
        )
      }

    ]
  },
  {
    path: 'homes',
    element: <SidebarLayout />,

    children: [
    
      {
        path: 'edit',
        element: (
          <ProtectedRoute>
            {' '}
            <AddHome />
          </ProtectedRoute>
        )
      },
  

    ]
  },

  {
    path: 'dashboards',

    element: <ProtectedRoute>
      <SidebarLayout />  </ProtectedRoute>,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="crypto" replace />
      // },
      {
        path: 'main',
        element: (
          <ProtectedRoute>
            {' '}
            <Crypto />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'messenger',
        element: (
          <ProtectedRoute>
            {' '}
            <Messenger />{' '}
          </ProtectedRoute>
        )
      }
    ]
  },
  
  {
    path: 'waiters',
    element: <SidebarLayout />,

    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            {' '}
            <WaiterList />
          </ProtectedRoute>
        )
      },
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <WaiterList />{' '}
          </ProtectedRoute>
        )
      },
      {
        path: 'add-waiter',
        element: (
          <ProtectedRoute>
            {' '}
            <AddWaiter />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit-waiter/:id',
        element: (
          <ProtectedRoute>
            {' '}
            <EditWaiter />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'matrimonial',
    element: <SidebarLayout />, // Added SidebarLayout here
    children: [
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <MatrimaonialList/>
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddMatrimonial />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit/:id',
        element: (
          <ProtectedRoute>
            <EditMatrimonial />
          </ProtectedRoute>
        )
      }
    ]
  },

  {
    path: 'condolance-messages',
    element: <SidebarLayout />, // Added SidebarLayout here
    children: [
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <CondolenceList/>
          </ProtectedRoute>
        )
      },
      {
        path: '',
        element: (
          <ProtectedRoute>
            <AddCondolanceMessage />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit/:id',
        element: (
          <ProtectedRoute>
            <EditCondolanceMessage />
          </ProtectedRoute>
        )
      }
    ]
  },

  {
    path: 'members',
    element: <SidebarLayout />, // Added SidebarLayout here
    children: [
      {
        path: 'list',
        element: (
          <ProtectedRoute>
            {' '}
            <MemberList/>
          </ProtectedRoute>
        )
      },
      {
        path: 'add',
        element: (
          <ProtectedRoute>
            <AddMembers />
          </ProtectedRoute>
        )
      },
      {
        path: 'edit/:id',
        element: (
          <ProtectedRoute>
            <EditMember />
          </ProtectedRoute>
        )
      }
    ]
  },
  
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },

    ]
  }
];

export default routes;
