import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Tooltip, Divider, Box, FormControl, InputLabel, Card, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme, CardHeader
} from '@mui/material';


import axios from 'axios';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}


const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    // if (filters.status && cryptoOrder.status !== filters.status) {
    //   matches = false;
    // }

    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const WaiterList: FC<CategoryListTableProps> = () => {
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>([]);
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'completed',
      name: 'Completed'
    },
    {
      id: 'pending',
      name: 'Pending'
    },
    {
      id: 'failed',
      name: 'Failed'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectOneCryptoOrder = (
    event: ChangeEvent<HTMLInputElement>,
    cryptoOrderId: string
  ): void => {
    if (!selectedCryptoOrders.includes(cryptoOrderId)) {
      setSelectedCryptoOrders((prevSelected) => [
        ...prevSelected,
        cryptoOrderId
      ]);
    } else {
      setSelectedCryptoOrders((prevSelected) =>
        prevSelected.filter((id) => id !== cryptoOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();

  const fetchCategoryData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/waiter/getall/?filter={}`);
      setcryptoOrdersPage(response?.data)
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });    }
  };


  const initialEmail = new URLSearchParams(location.search).get('email') || '';
  const initialName = new URLSearchParams(location.search).get('name') || '';



  useEffect(() => {
    fetchCategoryData();
  }, []);

  const handleDelete = async (id: any) => {
    try {
      let d = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/waiter/delete/${id}`);
      fetchCategoryData();
      toast.success('Waiter Deleted Successfully!', { position: "bottom-right" });
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status || 'all'}
                onChange={handleStatusChange}
                label="Status"
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="All Waiters"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>

              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>


              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCryptoOrders.map((cryptoOrder: any) => {
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              );
              return (
                <TableRow
                  hover
                  key={cryptoOrder.id}
                  selected={isCryptoOrderSelected}
                >

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {cryptoOrder?.name}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {cryptoOrder?.email}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {cryptoOrder?.mobile}
                    </Typography>

                  </TableCell>



                  <TableCell align="right">
                    {/* {cryptoOrder.status} */}
                    Active
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >

                        {/* <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/management/waiters/edit-waiter/${cryptoOrder?._id}
                        email=${encodeURIComponent(cryptoOrder?.email)}
                        &name=${encodeURIComponent(cryptoOrder?.name)}

                        
                        
                        `)} /> */}
                        <EditTwoToneIcon
                          fontSize="small"
                          onClick={() => navigate(
                            `/waiters/edit-waiter/${cryptoOrder?._id}?` +
                            `email=${encodeURIComponent(cryptoOrder?.email)}&` +
                            `name=${encodeURIComponent(cryptoOrder?.name)}`
                          )}
                        />

                      </IconButton>
                    </Tooltip>

                    {/* Delete Icon */}
                    <Tooltip title="Delete Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" onClick={() => handleDelete(cryptoOrder?._id)} />

                      </IconButton>
                    </Tooltip>

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default WaiterList;
