import { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Container,
  Divider,
  Grid
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router';
import axios from 'axios';
import Select from 'react-select';
import csc from 'country-state-city';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './EditUser.css';
import AWS from 'aws-sdk';
import AttachFileIcon from '@mui/icons-material/AttachFile';
AWS.config.update({
  accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
  secretAccessKey: '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',
  region: 'ap-south-1'
  // signatureVersion: 'v4'
});

const EditUser = () => {
  const AdminFlag = JSON.parse(localStorage.getItem('UserData'));
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  //errors
  const [firstNameErr, setfirstNameErr] = useState(false);
  const [fatherErr, setFatherErr] = useState(false);
  const [motherErr, setMotherErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const [MobileErr, setMobileErr] = useState(false);

  const [familyErr, setFamilyErr] = useState(false);
  const [gotraErr, setGotraErr] = useState(false);
  const [dobErr, setDobErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [areaErr, setAreaErr] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const [roleErrErr, setRoleErr] = useState(false);

  const [countryErr, setCountryErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [cityErr, setcityErr] = useState(false);

  //states
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setmobile] = useState('');
  const [allaya, setallaya] = useState('');
  const [domicile, setdomicile] = useState('');
  const [password, setpassword] = useState('');
  const [passwordErr, setpasswordErr] = useState(false);
  const [address, setaddress] = useState('');
  const [area, setarea] = useState('');
  const [fatherName, setfatherName] = useState('');
  const [motherName, setMotherName] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [gotra, setGotra] = useState('');
  const [selectMemberData, setSelectMemberData] = useState('');

  const [dob, setDOB] = useState('');
  const [familyId, setFamilyId] = useState('');
  const [hideNumber, setHideNumber] = useState(false);
  const [CurrentUserRole, setCurrentUserRole] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const countries = csc.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id
  }));

  const handleCheckboxChange = () => {
    setHideNumber(!hideNumber);
  };

  const [randomNumber, setRandomNumber] = useState(null);
  const [headMembers, setHeadMembers] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  function generateRandomFamilyId() {
    return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
  }

  useEffect(() => {
    let generateNumber = generateRandomFamilyId();
    setRandomNumber(generateNumber);
  }, []);

  const [submitData, setSubmitData] = useState(false);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [ids, setIds] = useState('');

  const GetUserByID = async () => {
    try {
      const data123 = JSON.parse(localStorage.getItem('UserData'));
      setIds(data123?.data?._id);

      const userData =
        location?.pathname === '/admin'
          ? await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/members/findone/${data123?.data?._id}`
            )
          : await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/members/findone/${id}`
            );

      const data = userData?.data;

      setSelectedOption(data?.head == true ? 'yes' : 'no');
      setSelectMemberData(data?.headMemberName);
      setFamilyId(data?.familyId);
      // selectedOption == "no" ? setFamilyId(data?.familyId) : ''

      // selectMemberData == "" ? "" : setFamilyId(data?.familyId);
      setName(data?.name);
      setEmail(data?.email);
      setmobile(data?.mobile);
      setallaya(data?.allaya);
      setdomicile(data?.domicile);
      setpassword(data?.password);
      setfatherName(data?.fatherName);
      setDOB(data?.dob);
      setGotra(data?.gotra);
      setaddress(data?.address);
      setMotherName(data?.motherName);
      setSpouseName(data?.spouse);

      setSelectedGenderStatus(data?.gender);
      setarea(data?.area);
      // setHideNumber(data?.hideNumber);
      setHideNumber(data?.hideNumber === true);
      setSelectedCountry(
        updatedCountries.find((country) => country.label === data?.country) ||
          null
      );
      setState(data?.state);
      setCity(data?.city);
      setCurrentUserRole(data?.role);
      setImage(data?.image);

      setSelectedMaritalStatus(data?.maritalStatus);
      setSelectedQualificationStatus(data?.qualification);
      setDataQualification(data?.qualification);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetUserByID();
  }, []);

  useEffect(() => {
    if (state) {
      setSelectedState(
        updatedStates(selectedCountry?.value).find(
          (state1) => state1?.label === state
        )
      );
    }
  }, [state]);
  useEffect(() => {
    if (city) {
      setSelectedCity(
        updatedCities(selectedState?.value).find(
          (city1) => city1.label === city
        )
      );
    }
  }, [city]);
  const [imageErr, setImageErr] = useState(false);
  const [img, setImg] = useState('');
  const s3 = new AWS.S3();

  async function handleImageFile(e) {
    setImageErr(false);
    setImage(e.target.files[0]?.name);

    if (e.target.files[0]?.name) {
      const params = {
        Bucket: 'chaturvedi-v2',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read-write'
      };
      try {
        const { Location } = await s3.upload(params).promise();
        setImg(Location);
        setImage(Location);
      } catch (error) {
        toast.error(error, {
          position: 'bottom-right'
        });
      }
    }
  }

  const [handleError, setError] = useState(false);

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      if (passwordErr == true) {
        return;
      }

      if (selectedOption != 'yes' && selectMemberData == '') {
        setError(true);
        return;
      }
      if (name == '') {
        setfirstNameErr(true);
        return;
      }

    //   if (email == '') {
    //     setEmailErr(true);
    //     return;
    //   }

      if (mobile.length > 0 && mobile.length !== 10) {
        setMobileErr(true);
        return;
      }

      if (fatherName == '' || fatherName == undefined) {
        setFatherErr(true);
        return;
      }

      if (dob == '' || dob == undefined) {
        setDobErr(true);
        return;
      }

      if (area == '') {
        setAreaErr(true);
        return;
      }
      if (address == '' || address == undefined) {
        setAddressErr(true);
        return;
      }
      if (CurrentUserRole == '' || CurrentUserRole == undefined) {
        setRoleErr(true);
        return;
      }
      if (gotra == '' || gotra == undefined) {
        setGotraErr(true);
        return;
      }

      if (selectedMaritalStatus == '' || selectedMaritalStatus == undefined) {
        setSelectedMaritalStatusError(true);
        return;
      }

      if (
        selectedQualificationStatus == '' ||
        selectedQualificationStatus == undefined
      ) {
        setSelectedQualificationStatusError(true);
        return;
      } else {
        if (dataQualification == '' || dataQualification == undefined) {
          setDataError(true);
          return;
        }
      }

      if (selectedCountry == '' || selectedCountry == undefined) {
        setCountryErr(true);
        return;
      }
      if (selectedState == '' || selectedState == undefined) {
        setstateErr(true);
        return;
      }
      if (selectedCity == '' || selectedCity == undefined) {
        setcityErr(true);
        return;
      }

      const data = {
        name,
        email,
        mobile,
        allaya,
        domicile,
        fatherName,
        motherName,
        dob,
        role: CurrentUserRole,
        gotra,
        area,
        address,
        familyId,
        gender: selectedGenderStatus,
        hideNumber,
        country: selectedCountry?.value || '',
        headMemberName: selectMemberData,
        head: selectedOption,
        state: selectedState?.value || '',
        city: selectedCity?.value || '',
        maritalStatus: selectedMaritalStatus,
        qualification:
          selectedQualificationStatus != 'Other'
            ? selectedQualificationStatus
            : dataQualification
      };

      console.log('data is = >>>>>>>>>>>>>>> ', data);
      let dataPassword;
      if (passwordData.length != 0) {
        dataPassword = passwordData;
      }

      try {
        // setSubmitData(true);

        const finaldata = {
          ...data,
          password: dataPassword,
          spouse: spouseName,
          image: img ? img : image,
          country:
            updatedCountries.find(
              (country) => country.value === selectedCountry?.value
            )?.label || '',
          state:
            updatedStates(selectedCountry?.value || '').find(
              (state) => state.value === selectedState?.value
            )?.label || '',
          city:
            updatedCities(selectedState?.value || '').find(
              (city) => city.value === selectedCity?.value
            )?.label || ''
        };

        let res =
          location?.pathname === '/admin'
            ? await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/members/updateone/${ids}`,
                finaldata
              )
            : await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/members/updateone/${id}`,
                finaldata
              );

        toast.success('User Update Successfully!', {
          position: 'bottom-right'
        });
        navigate('/users');
      } catch (error) {
        setSubmitData(false);
        toast.error(error?.response?.data?.message, {
          position: 'bottom-right'
        });
      }
    } catch (error) {
      setSubmitData(false);
      toast.error(error?.response?.data?.message, { position: 'bottom-right' });
    }
  };

  const updatedStates = (countryId) => {
    return csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id }));
  };
  const updatedCities = (stateId) => {
    return csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.id }));
  };

  const handleRoleSelectChange = (selectedOption) => {
    setRoleErr(false);

    setCurrentUserRole(selectedOption.value);
  };

  const handleGotraSelectChange = (selectedOption) => {
    setGotraErr(false);
    setGotra(selectedOption.value);
  };
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');
  const [selectedMaritalStatusError, setSelectedMaritalStatusError] =
    useState(false);

  const [selectedGenderStatus, setSelectedGenderStatus] = useState('');
  const [selectedGenderStatusError, setSelectedGenderError] = useState(false);

  const handleSelectMaritalChange = (selectedOption) => {
    setSelectedMaritalStatusError(false);
    setSelectedMaritalStatus(selectedOption.value);
  };

  const handleSelectGenderChange = (selectedOption) => {
    setSelectedGenderError(false);
    setSelectedGenderStatus(selectedOption.value);
  };

  // const [selectedQualificationStatus, setSelectedQualificationStatus] = useState('');
  const [
    selectedQualificationStatusError,
    setSelectedQualificationStatusError
  ] = useState(false);

  const handleSelectQualificationChange = (selectedOption) => {
    setSelectedQualificationStatusError(false);
    setSelectedQualificationStatus(selectedOption.value);
  };


  const gotrass = [
    { value: 'Daksh', label: 'Daksh' },
    { value: 'Kutsa', label: 'Kutsa' },
    { value: 'Vasistha', label: 'Vasistha' },
    { value: 'Bharghav', label: 'Bharghav' },
    { value: 'Bhardwaj', label: 'Bhardwaj' },
    { value: 'Dhamya', label: 'Dhamya' },
    { value: 'Shaushvas', label: 'Shaushvas' }
  ];

  const maritalStatus = [
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' }
  ];

  const genderStatus = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ];

  const qualificationStatus = [
    { value: 'BCA', label: 'BCA' },
    { value: 'MCA', label: 'MCA' },
    { value: 'BSC', label: 'BSC' },
    { value: 'MSC', label: 'MSC' },
    { value: 'BTech', label: 'BTech' },
    { value: 'BE', label: 'BE' },
    { value: 'BEd', label: 'BEd' },
    { value: 'MTech', label: 'MTech' },
    { value: 'Other', label: 'Other' }
  ];

  const [dataQualification, setDataQualification] = useState('');

  const [dataError, setDataError] = useState(false);
  const handleQualificationChange = (e) => {
    setDataError(false);
    setDataQualification(e.target.value);
  };

  const optionss = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Member', label: 'Member' }
    // { value: 'Sub Admin', label: 'Sub Admin' },
  ];

  function handleTextareaInput(event) {
    const textarea = event.target;
    textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
  }

  const options = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' }
  ];

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    // if (event.target.value == "yes") {
    //     setFamilyId("")
    // }
    if (event.target.value == 'no') {
      setSelectMemberData('');
    }
  };

  const handleSelectData = async () => {
    try {
      // head/member
      let d = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/members/head`
      );
      setHeadMembers(d?.data?.data);
    } catch (error) {
      console.log('error is  =>>>>>>>> ', error);
    }
  };

  useEffect(() => {
    handleSelectData();
  }, []);

  const member = headMembers.map((item) => ({
    value: item?.name,
    label: item?.name,
    value1: item?.mobile,
    label1: item?.mobile
  }));

  const handleSelectMember = (selectedOption) => {
    setSelectMemberData(selectedOption.value);

    if (selectedOption.value == 'no') {
      setFamilyId('');
    } else {
      const selectedMemberData = headMembers.find(
        (member) => member.name === selectedOption.value
      );
      if (selectedMemberData) {
        setFamilyId(selectedMemberData.familyId);
      } else {
        setFamilyId('');
      }
    }
  };

  // const handleSelectMember = (selectedOption) => {

  //     // setError(false);
  //     setSelectMemberData(selectedOption.value)
  // };

  const customFilter = (option, searchText) => {
    if (
      option?.data?.label?.toLowerCase().includes(searchText?.toLowerCase()) ||
      option?.data?.label1?.toLowerCase().includes(searchText?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [passwordData, setPasswordData] = useState('');

  const [passwordError, setPasswordError] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [selectedQualificationStatus, setSelectedQualificationStatus] =
    useState<string>('');
  const handleToggleClick = () => {
    setPasswordData(''); // Clear password field and any previous error
    setPasswordError(false); // Clear any previous error
    setIsToggled(!isToggled);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPasswordData(newPassword);

    // Validate the password
    if (/^\d{6}$/.test(newPassword)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit User</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Edit User" />
              <Divider />
              <CardContent>
                <form action="" onSubmit={handleEdit}>
                  <div className="box-1-top">
                    <div style={{ margin: 9 }}>
                      <TextField
                        id="outlined-required"
                        label="Name"
                        name="name"
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setfirstNameErr(false);
                        }}
                      />
                      {firstNameErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Name is required.
                        </span>
                      )}
                    </div>
                    {CurrentUserRole === 'Admin' ? (
                      <div>
                        <TextField
                          id="outlined-required"
                          label="Email"
                          name="email"
                          type="email"
                          value={email}
                          // disabled

                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailErr(false);
                            // setEmailError(false)
                          }}
                        />
                        {emailErr && (
                          <span
                            style={{
                              color: 'red',
                              display: 'flex',
                              paddingTop: '10px'
                            }}
                          >
                            Email is required
                          </span>
                        )}
                        {/* {emailError && (
                                            <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                Please Provide Valid Email
                                            </span>
                                        )} */}
                      </div>
                    ) : (
                      <div style={{ margin: 9 }}>
                        <TextField
                          id="outlined-required"
                          label="Email"
                          type="email"
                          name="email"
                          value={email}
                          // onChange={(e) => {
                          //     setName(e.target.value)
                          //     setfirstNameErr(false);
                          // }}
                          disabled
                        />
                      </div>
                    )}

                    <div style={{ margin: 9 }}>
                      <TextField
                        id="outlined-required"
                        label="Mobile"
                        type="number"
                        name="mobile"
                        value={mobile}
                        onChange={(e) => {
                          setmobile(e.target.value);
                          setMobileErr(false);
                        }}
                        // onChange={handleMobileCheange}
                        // disabled
                      />

                      {/* 'Mobile no. must be of 10 digits only.'; */}
                      {MobileErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Mobile no. must be of 10 digits only.
                        </span>
                      )}
                    </div>
                    {/* {CurrentUserRole === "Admin" && 
                                        <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Password"
                                                type='password'
                                                name="password"
                                                value={password}
                                                onChange={
                                                    (e) => {
                                                        setpassword(e.target.value)
                                                        setpasswordErr(false)
                                                    }}
                                            />
                                            {passwordErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Password must be exactly 6 digits and contain only numbers
                                                </span>
                                            )}
                                        </div>
                                        
                                        } */}

                    <div style={{ margin: 9 }}>
                      <TextField
                        id="outlined-required"
                        label="Father's Name"
                        name="fatherName"
                        type="text"
                        value={fatherName}
                        onChange={(e) => {
                          setfatherName(e.target.value);
                          setFatherErr(false);
                        }}
                      />
                      {fatherErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Father's Name is required.
                        </span>
                      )}
                    </div>

                    <div
                      className="hide-section-box"
                      style={{ margin: 9, width: '20%' }}
                    >
                      <span className="floating-title">Head of the member</span>
                      {options.map((option) => (
                        <label key={option.value}>
                          <input
                            type="radio"
                            name="radioGroup"
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={handleRadioChange}
                          />
                          {option.label}
                        </label>
                      ))}
                    </div>

                    {selectedOption == 'no' && (
                      <div style={{ margin: 9, width: '20%' }}>
                        <Select
                          name="member"
                          placeholder="List of Head"
                          value={member.find(
                            (option) => option.value === selectMemberData
                          )}
                          filterOption={customFilter}
                          onChange={(e) => {
                            handleSelectMember(e);
                            setError(false);
                          }}
                          options={member}
                        />
                        {handleError && (
                          <span
                            style={{
                              color: 'red',
                              display: 'flex',
                              paddingTop: '10px'
                            }}
                          >
                            This Field is required.
                          </span>
                        )}
                      </div>
                    )}

                    <div style={{ margin: 4 }}>
                      <TextField
                        id="outlined-required"
                        label="Mother's Name"
                        name="Mother"
                        value={
                          motherName?.trim() == 'undefined' ? '' : motherName
                        }
                        onChange={(e) => {
                          setMotherName(e.target.value);
                          // setMotherErr(false)
                        }}
                      />
                      {/* {motherErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Mother's Name  is required.
                                                </span>
                                            )} */}
                    </div>
                    <div style={{ margin: 9 }}>
                      <TextField
                        id="outlined-required"
                        label="Spouse's Name"
                        name="spouseName"
                        value={
                          spouseName?.trim() == 'undefined' ? '' : spouseName
                        }
                        onChange={(e) => {
                          setSpouseName(e.target.value);
                        }}
                      />
                    </div>

                    <div style={{ margin: 9 }}>
                      <TextField
                        className="date-box"
                        id="outlined-required"
                        label="Date of Birth"
                        type="date"
                        name="fatherName"
                        value={dob}
                        onChange={(e) => {
                          setDOB(e.target.value);
                          setDobErr(false);
                        }}
                      />
                      {dobErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          DOB is required
                        </span>
                      )}
                    </div>

                    <div style={{ margin: 9 }}>
                      <TextField
                        id="outlined-required"
                        label="Area"
                        name="area"
                        value={area}
                        onChange={(e) => {
                          setarea(e.target.value);
                          setAreaErr(false);
                        }}
                      />
                      {areaErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Area is required.
                        </span>
                      )}
                    </div>

                    {/* <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Address"
                                                name="address"
                                                value={address == 'undefined' ? '' : address}
                                                onChange={
                                                    (e) => {
                                                        setaddress(e.target.value)
                                                        setAddressErr(false)
                                                    }}
                                            />
                                            {addressErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Address is required
                                                </span>
                                            )}
                                        </div> */}

                    <div style={{ width: '19%', margin: 9 }}>
                      <Select
                        id="country"
                        name="country"
                        options={updatedCountries}
                        value={updatedCountries.find(
                          (country) => country.value === selectedCountry?.value
                        )}
                        onChange={(selectedOption) => {
                          setCountryErr(false);
                          setSelectedCountry(selectedOption);
                          setSelectedState(null);
                          setSelectedCity(null);
                        }}
                        placeholder="Select Country"
                      />
                      {countryErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Country is required.
                        </span>
                      )}
                    </div>

                    <div style={{ width: '19%', margin: 9 }}>
                      <Select
                        id="state"
                        name="state"
                        options={updatedStates(selectedCountry?.value || '')}
                        value={
                          state
                            ? updatedStates(selectedCountry?.value).find(
                                (state1) => state1?.label === state
                              )
                            : selectedState?.label
                            ? updatedStates(selectedCountry).find(
                                (state) => state.value === selectedState.value
                              )
                            : null
                        }
                        onChange={(selectedOption) => {
                          setState('');
                          setstateErr(false);
                          setSelectedState(selectedOption);
                          setSelectedCity(null);
                        }}
                        placeholder="Select State"
                      />
                      {stateErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          State is required.
                        </span>
                      )}
                    </div>

                    <div style={{ width: '19%', margin: 9 }}>
                      <Select
                        id="city"
                        name="city"
                        options={updatedCities(selectedState?.value || '')}
                        value={
                          city
                            ? updatedCities(selectedState?.value).find(
                                (city1) => city1.label === city
                              )
                            : updatedCities(selectedState).find(
                                (city) => city.value === selectedCity.value
                              )
                        }
                        onChange={(selectedOption) => {
                          setcityErr(false);
                          setCity('');
                          setSelectedCity(selectedOption);
                        }}
                        placeholder="Select City"
                      />
                      {cityErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          City is required.
                        </span>
                      )}
                    </div>

                    <div style={{ width: '19%', margin: 9 }}>
                      <Select
                        name="role"
                        placeholder="Select Role"
                        value={optionss.find(
                          (option) => option.value === CurrentUserRole
                        )}
                        onChange={(e) => {
                          handleRoleSelectChange(e);
                          setRoleErr(false);
                        }}
                        options={optionss}
                      />
                      {roleErrErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Role is required.
                        </span>
                      )}
                    </div>

                    <div style={{ width: '19%', margin: 9 }}>
                      <Select
                        name="gotra"
                        placeholder="Select Gotra"
                        value={gotrass.find((option) => option.value === gotra)}
                        onChange={(e) => {
                          handleGotraSelectChange(e);
                          setGotraErr(false);
                        }}
                        options={gotrass}
                      />
                      {gotraErr && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Gotra is required.
                        </span>
                      )}
                    </div>

                    <div style={{ margin: 9, width: '20%' }}>
                      <Select
                        name="qualification"
                        placeholder="Select Qualification Status"
                        value={qualificationStatus.find(
                          (option) =>
                            option.value === selectedQualificationStatus
                        )}
                        onChange={handleSelectQualificationChange}
                        options={qualificationStatus}
                      />

                      {selectedQualificationStatusError && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px',
                            fontSize: 11
                          }}
                        >
                          This is required field.
                        </span>
                      )}
                    </div>

                    {selectedQualificationStatus == 'Other' && (
                      <div style={{ margin: 9, width: '20%' }}>
                        <TextField
                          // className="add-user-inside"
                          label="Enter your Qualification"
                          name="qualification"
                          value={dataQualification}
                          onChange={(e) => {
                            handleQualificationChange(e);
                            setDataError(false);
                          }}
                        />
                        {dataError && (
                          <span
                            style={{
                              color: 'red',
                              display: 'flex',
                              paddingTop: '10px',
                              fontSize: 11
                            }}
                          >
                            This is required field.
                          </span>
                        )}
                      </div>
                    )}

                    <div style={{ margin: 9, width: '20%' }}>
                      <Select
                        name="marital"
                        placeholder="Select Marital Status"
                        value={maritalStatus.find(
                          (option) => option.value === selectedMaritalStatus
                        )}
                        onChange={(e) => {
                          handleSelectMaritalChange(e);
                          setSelectedMaritalStatusError(false);
                        }}
                        options={maritalStatus}
                      />
                      {selectedMaritalStatusError && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px',
                            fontSize: 11
                          }}
                        >
                          This is required field.
                        </span>
                      )}
                    </div>

                    <div style={{ margin: 9, width: '20%' }}>
                      <Select
                        name="gender"
                        placeholder="Select Gender"
                        value={genderStatus.find(
                          (option) => option.value === selectedGenderStatus
                        )}
                        onChange={(e) => {
                          handleSelectGenderChange(e);
                          setSelectedGenderError(false);
                        }}
                        options={genderStatus}
                      />
                      {selectedGenderStatusError && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px',
                            fontSize: 11
                          }}
                        >
                          This is required field.
                        </span>
                      )}
                    </div>

                    {/* {errors.qualification && (
                                            <span style={{ color: 'red', display: 'flex', paddingTop: '10px', fontSize: 11 }}>
                                                {errors.qualification}
                                            </span>
                                        )} */}

                    {selectedOption == 'no' ? (
                      <div style={{ margin: 9 }}>
                        <TextField
                          id="outlined-required"
                          label="Family"
                          name="familyId"
                          value={
                            selectedOption === 'no' && selectMemberData != ''
                              ? familyId
                              : ''
                          }
                          disabled={selectedOption === 'no'}
                          onChange={(e) => {
                            setFamilyId(e.target.value);
                            setFamilyErr(false);
                          }}
                        />
                        {familyErr && (
                          <span
                            style={{
                              color: 'red',
                              display: 'flex',
                              paddingTop: '10px'
                            }}
                          >
                            Family Id required.
                          </span>
                        )}
                      </div>
                    ) : null}

                    <div
                      className="image-section-box"
                      style={{
                        margin: 12,
                        width: '20%',
                        position: 'relative',
                        height: '53px'
                      }}
                    >
                      <span className="floating-title"> Hide Number </span>{' '}
                      <br></br>
                      <label className="checkbox-box">
                        <input
                          type="checkbox"
                          name="isChecked"
                          checked={hideNumber}
                          onChange={handleCheckboxChange}
                        />
                        Check this box
                      </label>
                    </div>

                    <div style={{ margin: 9, width: '20%' }}>
                      <TextField
                        className="add-user-inside"
                        label="Allaya"
                        name="allaya"
                        value={allaya}
                        onChange={(e) => setallaya(e.target.value)}
                      />
                    </div>

                    <div style={{ margin: 9, width: '20%' }}>
                      <TextField
                        className="add-user-inside"
                        label="Domicile"
                        name="domicile"
                        value={domicile}
                        onChange={(e) => setdomicile(e.target.value)}
                      />
                    </div>
                  </div>

                  <div style={{ margin: 9, width: '50%' }}>
                    <textarea
                      className="textarea-new"
                      placeholder="address"
                      id="address"
                      name="address"
                      value={address == 'undefined' ? '' : address}
                      onChange={(e) => {
                        handleTextareaInput(e);
                        setaddress(e.target.value);
                        setAddressErr(false);
                      }}
                      rows={4}
                      cols={50}
                    />
                    {addressErr && (
                      <span
                        style={{
                          color: 'red',
                          display: 'flex',
                          paddingTop: '10px'
                        }}
                      >
                        Address is required
                      </span>
                    )}
                  </div>

                  <div className="hide-btn-2" onClick={handleToggleClick}>
                    {isToggled ? 'Change Password ' : 'Hide password'}
                  </div>

                  {isToggled && (
                    <div className="enter-password-area">
                      <TextField
                        id="outlined-required"
                        label="Password"
                        type="password"
                        name="password"
                        value={passwordData}
                        onChange={handlePasswordChange}
                      />
                      {passwordError && (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            paddingTop: '10px'
                          }}
                        >
                          Password must be only Digit
                        </span>
                      )}
                    </div>
                  )}

                  <div
                    className=""
                    style={{ margin: 12, position: 'relative' }}
                  >
                    <input
                      className="image-input-class-2222"
                      type="file"
                      onChange={handleImageFile}
                    />
                    <div className="attach-icon">
                      <AttachFileIcon />
                      <label>choose an Image</label>
                    </div>
                    {image ? (
                      <img className="image-inside" src={image} />
                    ) : (
                      <img className="image-inside" src={img} />
                    )}
                  </div>

                  <div>
                    <Button
                      className="button-box"
                      type="submit"
                      disabled={submitData == true}
                    >
                      Submit
                    </Button>
                    <Button
                      className="button-box"
                      onClick={() => navigate('/users')}
                    >
                      Back to Users
                    </Button>
                  </div>
                </form>

                {/* <Box className='box-1-top'
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                </Box> */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EditUser;
