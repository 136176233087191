import { useLocation, useParams } from 'react-router';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import './ViewList.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';


const ViewContact = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    // const [startDate, setStartDate] = useState('');
    // const [endDate, setEndDate] = useState('');
    // const [locationData, setLocation] = useState('');
    // const [image, setImage] = useState('');
    // const [members, setMembers] = useState([])








    const viewData = async () => {

        try {
            const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contact/getData/${id}`)
      

            setDescription(data?.data?.data?.description);
            setSubject(data?.data?.data?.subject)

        } catch (error) {
            console.log("error is = ", error)
        }

    }
    useEffect(() => {
        viewData()
    }, [])


    return (


        <div className='detail-page-section'>
            <p className='info-text'>Contact Page</p>

            <div className='Section-new'>

                <div className='Main-box-1'>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Subject</p>
                        <p className='order-text-2'>{subject}</p>
                    </div>

                    <div className='order-info-box'>
                        <p className='order-text-1'>  Description</p>
                        <p className='order-text-2'>{description}</p>
                    </div>


                </div>
            </div>


            <div>
                <Button className='back-btn-2' onClick={() => navigate('/contacts')}>
                    Back to Contacts
                </Button>
            </div>
        </div>
    );


}
export default ViewContact;









