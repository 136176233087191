// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Box-inside{
    margin-bottom: 10px;
}

.done-btn{
    background: blue !important;    
    color: #fff !important;
}

.btn-box{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 12px;
}.Main-Box-1{
    display: flex;
    gap: 35px;
    align-items: flex-end;
    flex-wrap: wrap;
}

.Box-inside{
    margin-bottom: 10px;
}

.done-btn{
    background: blue !important;    
    color: #fff !important;
}

.btn-box{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 12px;
    
    margin-top: 30px;

}


.text-area-style{
    width: 384px;
    height: 170px;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    padding: 10px 25px;
}

.another-inside{
    margin: 12px;
    border: 1px solid #c4c4c4;
    border-radius: 7px;
    padding: 12px;
    width: 38%;
}

.image-div{
    max-width: 342px;
    height: 123px;
    width: 100%;
    object-fit: cover;
}

.box-2-padding{
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/content/applications/Notification/Edit-notification/EditNotification.css"],"names":[],"mappings":";;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,iBAAiB;AACrB,CAAC;IACG,aAAa;IACb,SAAS;IACT,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,iBAAiB;;IAEjB,gBAAgB;;AAEpB;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["\n\n.Box-inside{\n    margin-bottom: 10px;\n}\n\n.done-btn{\n    background: blue !important;    \n    color: #fff !important;\n}\n\n.btn-box{\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    margin-left: 12px;\n}.Main-Box-1{\n    display: flex;\n    gap: 35px;\n    align-items: flex-end;\n    flex-wrap: wrap;\n}\n\n.Box-inside{\n    margin-bottom: 10px;\n}\n\n.done-btn{\n    background: blue !important;    \n    color: #fff !important;\n}\n\n.btn-box{\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    margin-left: 12px;\n    \n    margin-top: 30px;\n\n}\n\n\n.text-area-style{\n    width: 384px;\n    height: 170px;\n    border-radius: 8px;\n    border: 1px solid #c4c4c4;\n    padding: 10px 25px;\n}\n\n.another-inside{\n    margin: 12px;\n    border: 1px solid #c4c4c4;\n    border-radius: 7px;\n    padding: 12px;\n    width: 38%;\n}\n\n.image-div{\n    max-width: 342px;\n    height: 123px;\n    width: 100%;\n    object-fit: cover;\n}\n\n.box-2-padding{\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
