import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import styles
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './home.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AWS from 'aws-sdk';
import axios from 'axios';

//storing image in bucket
AWS.config.update({
  accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
  secretAccessKey: '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',
  region: 'ap-south-1'
});

const AddHome = () => {
  const navigate = useNavigate();
  const s3 = new AWS.S3();

  const [content, setContent] = useState('');
  const [contentErr, setContentErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [images, setImages] = useState([]);
  const [loginImage, setLoginImage] = useState('');
  const [loginBanner, setLoginBanner] = useState('');
  const [id, setId] = useState('');

  const GetHomeData = async () => {
    try {
      const userData = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/home/getAll`
      );

      setId(userData?.data[0]?._id);
      setContent(userData?.data[0].description);
      setImages(userData?.data[0].images);
      setLoginBanner(userData?.data[0].loginBanner);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetHomeData();
  }, []);

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (content == '') {
        setContentErr(true);
        return;
      }

      // if (images.length == 0) {
      //     setImageErr(true)
      //     return;
      // }

      await axios
        .patch(`${process.env.REACT_APP_API_BASE_URL}/home/edit/${id}`, {
          description: content,
          images: images,
          loginBanner: loginImage
        })
        .then((res) => {
          toast.success('Home Update Successfully!', {
            position: 'bottom-right'
          });
          // navigate('/homes');
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: 'bottom-right'
          });
        });
    } catch (err) {
      console.log('Event Error 1 ====', err);
    }
  };

  async function handleImageFile(e) {
    setImageErr(false);

    const selectedImages = e.target.files;
    const imageList = [];

    for (let i = 0; i < selectedImages.length; i++) {
      const image = selectedImages[i];
      const imageName = `${Date.now()}.${image.name}`;
      const params = {
        Bucket: 'chaturvedi-v2',
        Key: imageName,
        Body: image,
        ACL: 'public-read-write'
      };

      try {
        const { Location } = await s3.upload(params).promise();
        imageList.push(Location);
      } catch (error) {
        toast.error(error, {
          position: 'bottom-right'
        });
      }
    }

    setImages([...images, ...imageList]);
  }

  async function handleLoginImage(e) {
    setImageErr(false);

    const selectedImages = e.target.files;

    // for (let i = 0; i < selectedImages.length; i++) {
    //   const image = selectedImages[i];
    const imageName = `${Date.now()}.${selectedImages[0].name}`;
    const params = {
      Bucket: 'chaturvedi-v2',
      Key: imageName,
      Body: selectedImages[0],
      ACL: 'public-read-write'
    };

    try {
      console.log(imageName, 'ooooooo');
      const { Location } = await s3.upload(params).promise();
      console.log(Location, 'ppppppp');
      setLoginImage(Location);
    } catch (error) {
      toast.error(error, {
        position: 'bottom-right'
      });
    }

    // setImages([...images, ...imageList]);
  }
  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '10',
          background: '#fff',
          padding: 15,
          margin: 30,
          borderRadius: 10
        }}
      >
        <div style={{ margin: 4, width: '100%' }}>
          <p
            style={{
              margin: 4,
              width: '100%',
              color: '#000',
              fontWeight: 600,
              marginBottom: '30px'
            }}
          >
            Edit Home
          </p>
        </div>
        <Grid item xs={12}>
          <div className="TopBox-Add-iser-2">
            <div style={{ margin: 9, width: '20%' }}>
              <textarea
                className="auto-resize-textarea-10"
                placeholder="Add Content"
                id="description"
                name="description"
                value={content}
                onChange={(e) => {
                  setContent(e.target.value);
                  setContentErr(false);
                }}
                rows={4}
                cols={50}
              />
              {contentErr && (
                <span
                  style={{ color: 'red', display: 'flex', paddingTop: '10px' }}
                >
                  This is required Field
                </span>
              )}
            </div>
          </div>

          <div
            className=""
            style={{ margin: 12, width: '20%', position: 'relative' }}
          >
            <input
              className=""
              type="file"
              multiple
              onChange={handleImageFile}
            />
            <div className="attach-icon">
              {/* <AttachFileIcon /> */}
              {/* <label>Choose Images</label> */}
            </div>
            {images.map((image, index) => (
              <div key={image?.id}>
                {image && image?.url ? (
                  <img
                    className="image-inside"
                    src={image?.url ? image?.url : ''}
                  />
                ) : (
                  ''
                )}

                <img className="image-inside" src={image ? image : ''} />
                <button onClick={() => deleteImage(index)}>Delete</button>
              </div>
            ))}
          </div>
        </Grid>
        <div style={{ margin: 4, width: '100%' }}>
          <p
            style={{
              margin: 4,
              width: '100%',
              color: '#000',
              fontWeight: 600,
              marginBottom: '30px'
            }}
          >
            Edit Login
          </p>
        </div>
        <Grid item xs={12}>
          <div
            className=""
            style={{ margin: 12, width: '20%', position: 'relative' }}
          >
            <input className="" type="file" onChange={handleLoginImage} />
            {/* <div className="attach-icon">
              <AttachFileIcon />
              <label>Choose Images</label>
            </div> */}

            <div key={loginBanner}>
              {loginBanner && loginBanner ? (
                <img
                  className="image-inside"
                  src={loginBanner ? loginBanner : ''}
                />
              ) : (
                ''
              )}

              {/* <img className="image-inside" src={image ? image : ''} /> */}
              {/* <button onClick={() => deleteImage(0)}>Delete</button> */}
            </div>
          </div>
        </Grid>
        <div style={{ margin: 9, width: '100%' }}>
          {/* <Button variant="contained" type="submit" disabled={addButton == true}> */}
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
export default AddHome;
