import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Tooltip, Modal, Divider, Box, FormControl, InputLabel, Card, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableContainer, Select, MenuItem, Typography, useTheme, CardHeader, Button
} from '@mui/material';
import { DataGrid, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import './ListEvent.css';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface CategoryListTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}


const applyFilters = (
  cryptoOrders: any[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EventListTable: FC<CategoryListTableProps> = ({ }) => {
  const [clickedID, setClickedID] = useState(null)
  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    setOpen(true);
    setClickedID(id)
  }



  const handleClose = () => {
    setOpen(false);
    setClickedID(null)
  }

  const columns = [
    { field: 'title', headerName: 'Title', width: 170 },
    { field: 'country', headerName: 'Country', width: 270 },
    { field: 'state', headerName: 'State', width: 170 },
    { field: 'city', headerName: 'City', width: 170 },
    // { field: 'startDate', headerName: 'State', width: 170 },
    // { field: 'familyId', headerName: 'FamilyId', width: 170 },

    {
      field: 'Actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Event" arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  },
                  color: theme.palette.primary.main
                }}
                color="inherit"
                size="small"
              >

                <EditTwoToneIcon fontSize="small" onClick={() => navigate(`/events/edit-event/${params?.row._id}`)} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Event" arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  },
                  color: theme.palette.primary.main
                }}
                color="inherit"
                size="small"
              >
                <DeleteTwoToneIcon fontSize='small'
                  onClick={() => handleOpen(params.row._id)}
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit Order" arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  },
                  color: theme.palette.primary.main
                }}
                color="inherit"
                size="small"

              >
                <VisibilityIcon fontSize="small" onClick={() => navigate(`/events/view-event/${params?.row._id}`)} />
              </IconButton>
            </Tooltip>



          </>

        );
      },
    },
  ];

  const [Loading, setLoading] = useState(false)
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState([]);
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();
  const navigate = useNavigate();

  const fetchUserData = async () => {

    try {
      setLoading(true);

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/event/listing`);
      if (response?.data?.data) {
        setLoading(false);
        const d = response?.data?.data;
        const today = new Date().toISOString().split('T')[0];
        const upcoming = d.filter(event => event.createdAt >= today);
        const daaaa = upcoming
        const sortedNonPinnedPosts = daaaa.sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });

        setcryptoOrdersPage(sortedNonPinnedPosts)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }

  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [deleteButton, setDeleteButton] = useState(false)

  const [selectedFilter, setSelectedFilter] = useState('upcoming')

  const handleDeleteData = async (id: any) => {
    try {
      setDeleteButton(true);
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/event/${id}`);
      toast.success('Event Deleted Successfully!', { position: "bottom-right" });
      setDeleteButton(false);
      handleClose();
      const deletedEvent = cryptoOrders.find(event => event._id === id);
      const eventDate = new Date(deletedEvent.createdAt);



      if (eventDate >= new Date()) {


        setSelectedFilter('upcoming');
        handleChangeEventUpcomming();
      } else {


        setSelectedFilter('previous');
        handleChangeEventPrevious();
      }

      // fetchUserData();
    } catch (error) {
      setDeleteButton(false);
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  }





  const handleChangeEventUpcomming = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/event/listing`);
      if (response?.data?.data) {
        setLoading(false);
        const d = response?.data?.data;
        const today = new Date().toISOString().split('T')[0];
        const upcoming = d.filter(event => event.endDate >= today).sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });

        setcryptoOrdersPage(upcoming)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }

  useEffect(() => {
    handleChangeEventUpcomming()
  }, [])

  const handleChangeEventPrevious = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/event/listing`);
      if (response?.data?.data) {
        setLoading(false);
        const d = response?.data?.data;
        const today = new Date().toISOString().split('T')[0];
        const previous = d.filter(event => event.endDate < today);
        setcryptoOrdersPage(previous)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }


  const handleDeleteUsers = async () => {
    try {
      setDeleteButton(true);
      for (const id of selectedCryptoOrders) {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/event/${id}`);
      }
      setDeleteButton(false);
      toast.success('Selected Events Deleted Successfully!', { position: "bottom-right" });
      setSelectedCryptoOrders([]);
      fetchUserData();
    } catch (error) {
      setDeleteButton(false);
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  };


  return (

    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Are you sure to delete?
          </Typography>
          <hr />


          <Button type="submit" sx={{ mt: 2, mr: 2 }} variant="contained" onClick={() => handleDeleteData(clickedID)}
            disabled={deleteButton}
          >
            Yes
          </Button>

          <Button type="submit" sx={{ mt: 2 }} variant="contained" onClick={() => handleClose()}>
            Cancel
          </Button>

        </Box>
      </Modal>

      <Box className="innerr_box" sx={{ p: 3, width: "auto" }}>
        {/* <Divider variant="middle" /> */}
        <div className="table">

          <div className='ButtonBox'>
            <Button
              variant={selectedFilter === 'upcoming' ? 'contained' : 'outlined'}
              onClick={() => {
                handleChangeEventUpcomming()
                setSelectedFilter('upcoming')
              }}>
              Upcoming Events
            </Button>


            <Button
              variant={selectedFilter === 'previous' ? 'contained' : 'outlined'}
              onClick={() => {
                console.log("in past");

                handleChangeEventPrevious()
                setSelectedFilter('previous')
              }
              }
            >
              Past Events
            </Button>
          </div>


          <DataGrid
            rows={cryptoOrders}
            getRowId={(row) => row._id}
            columns={columns}
            className="mybonustable widthtable"
            slots={{ toolbar: GridToolbar }}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedCryptoOrders(newSelection);
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: ['']
                }
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
          />
        </div>


        <Button
        className='dlt-btn'
          variant="contained"
          color="warning"
          onClick={handleDeleteUsers}
          disabled={selectedCryptoOrders.length === 0 || deleteButton}
          style={{ display: selectedCryptoOrders.length === 0 || deleteButton ? 'none' : 'block' }}
        >
          Delete Events
        </Button>
      </Box>


    </>


  );
};

export default EventListTable;
