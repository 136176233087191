import { useEffect, useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import {  useNavigate, useParams } from 'react-router';
import axios from 'axios';

import Select from 'react-select';
import csc from "country-state-city"
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './EditEvent.css';
import AWS from 'aws-sdk';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
    // signatureVersion: 'v4'
});



const EditEvent = () => {

    const navigate = useNavigate();
    const { id } = useParams();




    const [editorContent, setEditorContent] = useState('');
    const removeHTMLTags = (str) => {
        const div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText || "";
    };

    const today = new Date().toISOString().split('T')[0];
    const [minEndDate, setMinEndDate] = useState(today);
    //errors
    const [titleErr, setTitleErr] = useState(false);
    const [descriptionErr, setDescriptionErr] = useState(false)
    const [startDateErr, setStartDateErr] = useState(false);
    const [endDateErr, setEndDateErr] = useState(false)
    const [locationErr, setLocationErr] = useState(false);
    const [countryErr, setCountryErr] = useState(false);
    const [stateErr, setstateErr] = useState(false);
    const [cityErr, setcityErr] = useState(false);


    //states
    const [title, setTitle] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [locationData, setLocation] = useState('');
    const [image, setImage] = useState('')
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
    }));



    const [submitData, setSubmitData] = useState(false);

    const [state, setState] = useState('');
    const [city, setCity] = useState('');


    const GetUserByID = async () => {
        try {
            const userData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/event/${id}`);
            const data = userData?.data?.data;
            const formattedStartDate = new Date(data?.startDate).toISOString().split('T')[0];
            const formattedEndDate = new Date(data?.endDate).toISOString().split('T')[0];

            setTitle(data?.title);
            setEditorContent(data?.description);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            setLocation(data?.location);
            setSelectedCountry(updatedCountries.find((country) => country.label === data?.country) || null);
            setState(data?.state)
            setCity(data?.city)
            setImage(data?.image)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetUserByID()
    }, [])

    useEffect(() => {
        if (state) {
            setSelectedState(updatedStates(selectedCountry?.value).find((state1) => state1?.label === state))
        }
    }, [state])
    useEffect(() => {
        if (city) {
            setSelectedCity(updatedCities(selectedState?.value).find((city1) => city1.label === city))
        }
    }, [city])

    const [imageErr, setImageErr] = useState(false);
    const [img, setImg] = useState('');
    const s3 = new AWS.S3();

    async function handleImageFile(e) {
        setImageErr(false);
        const selectedFile = e.target.files[0];



        if (selectedFile) {
            if (selectedFile.size > 5 * 1024 * 1024) { // Check if the file size is greater than 2MB
                setImageErr(true);
                toast.error('Image size must be 5MB or less', {
                    position: "bottom-right",
                });
                return;
            }

            setImage(selectedFile.name);

            const params = {
                Bucket: 'chaturvedi-v2',
                Key: `${Date.now()}.${selectedFile.name}`,
                Body: selectedFile,
                ACL: 'public-read-write'
            };

            try {
                const { Location } = await s3.upload(params).promise();
                setImg(Location);
                setImage(Location)
            } catch (error) {
                toast.error(error, {
                    position: "bottom-right",
                });
            }
        }
    }

    const handleEdit = async (e) => {
        try {
            e.preventDefault();

            if (title == '') {
                setTitleErr(true);
                return;
            }



            const cleanedEditorContent = removeHTMLTags(editorContent);
            if (cleanedEditorContent === '') {
                setDescriptionErr(true);
                return;
            }

            if (startDate == '') {
                setStartDateErr(true);
                return;
            }


            if (endDate == '') {
                setEndDateErr(true);
                return;
            }

            if (endDate == '') {
                setEndDateErr(true);
                return;
            }



            if (selectedCountry == '' || selectedCountry == undefined) {
                setCountryErr(true);
                return;
            }
            if (selectedState == '' || selectedState == undefined) {
                setstateErr(true);
                return;
            }
            if (selectedCity == '' || selectedCity == undefined) {
                setcityErr(true);
                return;
            }



            if (locationData == '') {
                setLocationErr(true);
                return;
            }




            const data = {
                title,
                startDate,
                country: selectedCountry?.value || '',
                state: selectedState?.value || '',
                city: selectedCity?.value || '',
            };

            


            setSubmitData(true)
            const finaldata = {
                ...data,
                description: editorContent,
                image: img ? img : image,
                location: locationData,
                endDate: checked ? startDate : endDate,
                country: updatedCountries.find((country) => country.value === selectedCountry?.value)?.label || '',
                state: updatedStates(selectedCountry?.value || '').find((state) => state.value === selectedState?.value)?.label || '',
                city: updatedCities(selectedState?.value || '').find((city) => city.value === selectedCity?.value)?.label || '',
            };

            try {
                let res = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/event/edit/${id}`, finaldata);
                toast.success('Event Update Successfully!', { position: "bottom-right" });
                navigate('/events');

            } catch (error) {
                setSubmitData(false);
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }

        } catch (error) {
            setSubmitData(false);
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }

    const updatedStates = (countryId) => {
        return csc
            .getStatesOfCountry(countryId)
            .map((state) => ({ label: state.name, value: state.id }));
    }
    const updatedCities = (stateId) => {
        return csc
            .getCitiesOfState(stateId)
            .map((city) => ({ label: city.name, value: city.id }));
    }



    const [checked, setChecked] = useState(today === startDate ? true : false);

    const handleCheckboxChange = () => {
        setChecked(!checked);

        if (!checked) {
            setEndDate('')
        }
    };


    const getMinimumDate = (date) => {
        const minDate = new Date(date);
        minDate.setDate(minDate.getDate() + 1);
        return minDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    };


    return (
        <>
            <Helmet>
                <title>Edit User</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Event" />
                            <Divider />
                            <CardContent>

                                <form action="" onSubmit={handleEdit}>

                                    <div className='box-1-top-22'>
                                        <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Title"
                                                name="title"
                                                type='text'
                                                value={title}
                                                onChange={(e) => {
                                                    setTitle(e.target.value)
                                                    setTitleErr(false);
                                                }}
                                            />
                                            {titleErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Title is required.
                                                </span>
                                            )}
                                        </div>



                                        <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Location"
                                                name="location"
                                                value={locationData}
                                                onChange={
                                                    (e) => {
                                                        setLocation(e.target.value)
                                                        setLocationErr(false)

                                                    }}
                                            />
                                            {locationErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Location is required.
                                                </span>
                                            )}
                                        </div>




                                        <div>
                                            <TextField className='date-box'
                                                id="outlined-required"
                                                type='date'
                                                name="startDate"
                                                value={startDate}
                                                inputProps={{
                                                    min: today,
                                                }}
                                                onChange={
                                                    (e) => {
                                                        setStartDate(e.target.value)
                                                        setStartDateErr(false)
                                                        setMinEndDate(e.target.value);

                                                    }}
                                            />
                                            {startDateErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Start Date is required
                                                </span>
                                            )}
                                        </div>

                                        {
                                            startDate === today ? (
                                                <div className='image-section-box' style={{ margin: 12, width: '20%' }}>
                                                    <span>Today Date </span> <br></br>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="isChecked"
                                                            checked={today === startDate ? checked : false}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        Start Date is same as end date
                                                    </label>
                                                </div>
                                            ) : null
                                        }



                                        {
                                            !checked ? (
                                                <div>
                                                    <TextField className='date-box'
                                                        id="outlined-required"
                                                        type='date'
                                                        name="endDate"
                                                        value={endDate}
                                                        inputProps={{
                                                            min: startDate == today ? getMinimumDate(minEndDate) : minEndDate
                                                        }}

                                                        onChange={
                                                            (e) => {
                                                                setEndDate(e.target.value)
                                                                setEndDateErr(false)
                                                            }}
                                                    />
                                                    {endDateErr && (
                                                        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                            End Date is required
                                                        </span>
                                                    )}
                                                </div>
                                            ) : null
                                        }





                                        <div style={{ margin: 9, width: '20%' }}>
                                            <Select
                                                id="country"
                                                name="country"
                                                options={updatedCountries}
                                                value={updatedCountries.find((country) => country.value === selectedCountry?.value)}
                                                onChange={(selectedOption) => {
                                                    setCountryErr(false)
                                                    setSelectedCountry(selectedOption);
                                                    setSelectedState(null);
                                                    setSelectedCity(null);
                                                }}
                                                placeholder='Select Country'
                                            />
                                            {countryErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Country is required.
                                                </span>
                                            )}


                                        </div>

                                        <div style={{ margin: 9, width: '20%' }}>
                                            <Select
                                                id="state"
                                                name="state"
                                                options={updatedStates(selectedCountry?.value || '')}
                                                value={state ? updatedStates(selectedCountry?.value).find((state1) => state1?.label === state) : selectedState?.label ? (updatedStates(selectedCountry).find((state) => state.value === selectedState.value)) : null}
                                                onChange={(selectedOption) => {
                                                    setState('')
                                                    setstateErr(false)
                                                    setSelectedState(selectedOption);
                                                    setSelectedCity(null);
                                                }}
                                                placeholder='Select State'
                                            />
                                            {stateErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    State is required.
                                                </span>
                                            )}
                                        </div>

                                        <div style={{ margin: 9, width: '20%' }}>
                                            <Select
                                                id="city"
                                                name="city"
                                                options={updatedCities(selectedState?.value || '')}
                                                value={city ? updatedCities(selectedState?.value).find((city1) => city1.label === city) : updatedCities(selectedState).find((city) => city.value === selectedCity.value)}
                                                onChange={(selectedOption) => {
                                                    setcityErr(false)
                                                    setCity('')
                                                    setSelectedCity(selectedOption);
                                                }}
                                                placeholder='Select City'

                                            />
                                            {cityErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    City is required.
                                                </span>
                                            )}
                                        </div>

                                    </div>


                                    <div className='description-image-box'>
                                        <div style={{ margin: 12, width:'40%' }}>
                                            <label>Description</label>
                                            <ReactQuill
                                                value={editorContent}
                                                onChange={(e) => {
                                                    setEditorContent(e)
                                                    setDescriptionErr(false)

                                                }}
                                                modules={{
                                                    toolbar: [
                                                        [{ header: '1' }, { header: '2' }],
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                                        ['link'],
                                                    ],
                                                }}
                                                placeholder="Write a description..."
                                            />
                                            {descriptionErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Description is required.
                                                </span>
                                            )}
                                        </div>


                                    </div>
                                    <div className='' style={{ margin: 12, position: 'relative' }} >
                                        <input className='image-input-class-22' type="file" onChange={handleImageFile} />
                                        <div className='attach-icon'>
                                            <AttachFileIcon />
                                            <label>choose an Image</label>
                                        </div>
                                        {image && <img className='image-inside' src={image} />}

                                    </div>

                                    <div>
                                        <Button className="button-box" type='submit' disabled={submitData == true}>Submit</Button>
                                        <Button className="button-box" onClick={() => navigate('/events')}>Back to Events</Button>
                                    </div>
                                </form>

                                {/* <Box className='box-1-top'
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditEvent;
