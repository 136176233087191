import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useNavigate } from 'react-router';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';


import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput
} from '@mui/material';
const defaultTheme = createTheme();

interface FormData {
  email: string;
  password: string;
}
const initialValue: FormData = {
  email: '',
  password: ''
};

const SignIn = () => {
  const navigate = useNavigate();
  const [loggedIn, setloggedIn] = useState(false);
  const [formData, setFormData] = useState<FormData>(initialValue);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [showPassword, setShowPassword] = useState(false);

  const ValidateForm = () => {
    const errors: Partial<FormData> = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData?.email.trim()) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData?.password.trim()) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors((preError) => ({
      ...preError,
      [name]: undefined
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const err = ValidateForm();
    if (Object.keys(err).length === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/members/login`,
          formData
        );
        if (response?.data?.message === "Login Success") {
          setloggedIn(true);
          localStorage.setItem("UserData", JSON.stringify(response?.data))
        }
        navigate('/dashboards/main');
        
        toast.success("Admin Login Successfully", { position: "bottom-right" });

      } catch (error) {
        console.log("Error is ->>>>>>>>>>> ", error)
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
        });
      }
    } else {
      setErrors(err);

    }
  };

  useEffect(() => {
    localStorage.setItem('IsloggedIn', JSON.stringify(loggedIn));
  }, [loggedIn]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <img className='bg-image' src='/static/images/avatars/bg-3.jpg'/> */}
      <div className='Main-container-1'>
      <Container className='middle-section-container' component="main" maxWidth="xs">
      
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
           
            <img className='logo-img' src='/static/images/avatars/admin.jpg' alt="My Image"  style={{width:"90px"}}/>

          </Avatar>
          {/* <Typography component="h1" variant="h5" style={{color:'blue'}}>
            Sign in
          </Typography> */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              autoComplete="email"
              autoFocus
            />
            {errors?.email && (
              <span style={{ color: 'red', paddingTop: '15px', marginBottom: '10px' }}>
                {errors.email}
              </span>
            )}

            

            <FormControl className='password-box' sx={{ width: '100%' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="eye-icon"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="primary"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                placeholder='Password'
                name="password"
                value={formData?.password}
                onChange={handleChange}
                autoComplete="password"
              autoFocus
                
              />
            </FormControl>

            {/* <div style={{display:'flex'}}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                onChange={handleChange}
                value={formData?.password}
                autoComplete="current-password"
              />
              <IconButton
                className="eye-icon"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                color="primary"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div> */}

            {errors?.password && (
              <span style={{ color: 'red', paddingTop: '10px' }}>
                {errors.password}
              </span>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
      </div>
    </ThemeProvider>
  );
};

export default SignIn;
