

import { FC, useState, useEffect } from 'react';
import { Tabs, Tab, Tooltip, Box, IconButton, Typography, } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './userNotFound.css';
import { useNavigate } from 'react-router';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Link } from 'react-router-dom';


interface CategoryListTableProps {
  className?: string;
}




interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}




const UserNotFound: FC<CategoryListTableProps> = ({ }) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate()



  const handleInProcess = async (id) => {
    try {
      const data = { status: "Progress" }
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/contact/udpate/${id}`, data);
      setValue(1);
      fetchUserProgressData()
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }



  const handleInComplete = async (id) => {
    try {
      const data = { status: "Complete" }
      await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/contact/udpate/${id}`, data);
      setValue(2);
      fetchUserCompleteData()
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  }


  // const handleNameClick = (memberId) => {
  //   // Handle the click on the 'name' cell with the member ID
  //   navigate(`/users/edit-user/${memberId}`);
  // };




  const columns = [
   
    {
        field: 'name',
        headerName: 'Name',
        width: 170,
        renderCell: (params) => (
            <span
              style={{ cursor: 'pointer' }}
              // onClick={() => handleNameClick(params.row._id)}
            >
              {params.row.name}
            </span>
          
        )
        },
      
      {
        field: 'mobile',
        headerName: 'PhoneNumber',
        width: 170,
        renderCell: (params) => (
            <span
              style={{ cursor: 'pointer' }}
              // onClick={() => handleNameClick(params.row._id)}
            >
              {params?.row?.mobile}
            </span>
        
        )
        }
      
      



    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   sortable: false,
    //   width: 240,
    //   renderCell: (params) => {

    //     if (value === 0) {
    //       return (
    //         <div className='process-box'>
    //           <button
    //             className="custom-button"
    //             onClick={() => { handleInProcess(params.row._id) }}
    //           >
    //             In Process
    //           </button>
    //           <button
    //             className="custom-button"
    //             onClick={() => { handleInComplete(params.row._id) }}>
    //             Complete
    //           </button>
    //           {/* view-list */}
    //           <Tooltip title="View Contact" arrow>
    //             <VisibilityIcon fontSize="small" onClick={() => navigate(`/contacts/view-list/${params?.row._id}`)} />
    //           </Tooltip>





    //         </div>
    //       );
    //     } else if (value === 1) {
    //       return (
    //         <div className='process-box'>
    //           <button
    //             className="custom-button"
    //             onClick={() => { handleInComplete(params.row._id) }}>
    //             Complete
    //           </button>
    //           <Tooltip title="View Contact" arrow>
    //             <VisibilityIcon fontSize="small" onClick={() => navigate(`/contacts/view-list/${params?.row._id}`)} />
    //           </Tooltip>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className='process-box'>
    //           <span> Completed</span>
    //           <Tooltip title="View Contact" arrow>
    //             <VisibilityIcon fontSize="small" onClick={() => navigate(`/contacts/view-list/${params?.row._id}`)} />
    //           </Tooltip>
    //         </div>
    //       );
    //     }
    //   },
    // },
  ];



  const [Loading, setLoading] = useState(false)
  const [cryptoOrders, setcryptoOrdersPage] = useState([]);
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState([]);




  const fetchUserPendingData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/userNotFound/list`);
      console.log("response => ", response?.data?.data);

      if (response?.data?.data) {
        setcryptoOrdersPage(response?.data?.data)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };


  useEffect(() => {
    fetchUserPendingData()
  }, [])

  const fetchUserProgressData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contact/list/progress`);
      if (response?.data?.data) {
        setcryptoOrdersPage(response?.data?.data)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };

  const fetchUserCompleteData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contact/list/complete`);
      if (response?.data?.data) {
        setcryptoOrdersPage(response?.data?.data)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, { position: "bottom-right" });
    }
  };






  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      fetchUserPendingData();
    }
    if (newValue === 1) {
      fetchUserProgressData();
    }
    if (newValue === 2) {
      fetchUserCompleteData();
    }
  };



  return (

    <>
      <Box sx={{ p: 3 }}>
        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
          {/* <Tab label="New" {...a11yProps(0)} /> */}
          {/* <Tab label="In Process" {...a11yProps(1)} /> */}
          {/* <Tab label="Resolve" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <div className="table" style={{ backgroundColor: 'white' }}>

          <DataGrid
            rows={cryptoOrders}
            getRowId={(row) => row._id}
            columns={columns}
            className="mybonustable widthtable"
            slots={{ toolbar: GridToolbar }}
            // onCellClick={(params, event) => handleCellClick(params, event)} // Handle cell click event            // checkboxSelection
            // onRowSelectionModelChange={(newSelection) => {
            //   setSelectedCryptoOrders(newSelection);
            // }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: ['']
                }
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
          />
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <div className="table">


          <DataGrid
            rows={cryptoOrders}
            getRowId={(row) => row._id}
            columns={columns}
            className="mybonustable widthtable"
            slots={{ toolbar: GridToolbar }}
            // checkboxSelection
            // onRowSelectionModelChange={(newSelection) => {
            //   setSelectedCryptoOrders(newSelection);
            // }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: ['']
                }
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
          />
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <div className="table" style={{ backgroundColor: 'white' }}>


          <DataGrid
            rows={cryptoOrders}
            getRowId={(row) => row._id}
            columns={columns}
            className="mybonustable widthtable"
            slots={{ toolbar: GridToolbar }}
            // checkboxSelection
            // onRowSelectionModelChange={(newSelection) => {
            //   setSelectedCryptoOrders(newSelection);
            // }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: ['']
                }
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
          />
        </div>
      </CustomTabPanel>




    </>


  );
};

export default UserNotFound;

