// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
    background-color: #007bff; /* Set your desired background color */
    color: #ffffff; /* Set your desired text color */
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    margin-right: 8px; /* Add margin for spacing between buttons */
  }

  .process-box{

    display: flex;
    align-items: center;
    gap: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/content/applications/Contact/contact.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,sCAAsC;IACjE,cAAc,EAAE,gCAAgC;IAChD,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB,EAAE,2CAA2C;EAChE;;EAEA;;IAEE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV","sourcesContent":[".custom-button {\n    background-color: #007bff; /* Set your desired background color */\n    color: #ffffff; /* Set your desired text color */\n    border: none;\n    padding: 8px 16px;\n    cursor: pointer;\n    margin-right: 8px; /* Add margin for spacing between buttons */\n  }\n\n  .process-box{\n\n    display: flex;\n    align-items: center;\n    gap: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
