import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import HeaderButtons from './layouts/SidebarLayout/Header/Buttons';

const socket = io(`${process.env.REACT_APP_API_BASE_URL}`, { transports: ['polling'] });
// https://api.vid-food.stageprojects.xyz

socket.on('connect', () => {
  console.log('App Connected Server');
});

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ToastContainer />
        <HeaderButtons />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export { App, socket }; 