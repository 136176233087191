import React from 'react';
import { Navigate, Route, Outlet, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const Isloggedin = JSON.parse(localStorage.getItem('IsloggedIn'));
  const location = useLocation();

  return Isloggedin ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
export default ProtectedRoute;
